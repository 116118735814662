import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Colors from "../../styles/theme/Colors";
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";
import { getAllPilots } from "../../services/APIService";

const Search = () => {
  const [isListOpen, setListOpen] = useState(false);
  const [pilots, setPilots] = useState([]);
  const navigate = useNavigate();

  const listOpened = () => {
    setListOpen(!isListOpen);
  };
  const linkStyle = {
    textDecoration: "none",
    color: Colors.divider,
    padding: "10px",
  };

  useEffect(() => {
    getAllPilots().then((pilots) => {
      setPilots(pilots);
    });
  }, []);

  const filterOptions = (options, { inputValue }) => {
    return options.filter((option) => {
      const searchValue = inputValue.toLowerCase();
      return (
        option.name.toLowerCase().includes(searchValue) ||
        option.emp_id.toLowerCase().includes(searchValue) ||
        (option.int_id &&
          option.int_id.toString().toLowerCase().includes(searchValue)) // Convert int_id to a string before calling toLowerCase()
          || option.lname.toLowerCase().includes(searchValue)
      );
    });
  };

  return (
    <Autocomplete
      sx={{
        width: { lg: 700, sm: 500, xs: "100%" },
        "& fieldset": { borderRadius: 33 },
        "& .MuiAutocomplete-popupIndicator": { transform: "none" },
        "& .MuiAutocomplete-endAdornment": { top: "5px" },
      }}
      onChange={(event, value, reason) => {
        if (reason == "selectOption") {
          if (value.emp_id !== undefined) {
            navigate("/profile/" + value.emp_id);
          }
        }
      }}
      options={pilots}
      freeSolo
      getOptionLabel={(option) =>
        option.emp_id != undefined
          ? option.name +
            " " +
            (option.mname ? option.mname : "") +
            option.lname +
            " (" +
            option.emp_id +
            ")"
          : ""
      }
      renderOption={(props, option) => (
        <Link
          style={linkStyle}
          to={{ pathname: "/profile/" + option.emp_id }}
          key={option.emp_id}
        >
          <Box
            component="li"
            sx={{
              "& > img": { mr: 2, flexShrink: 0 },
            }}
            {...props}
          >
            {option.name} {option.mname} {option.lname} ({option.emp_id})
          </Box>
        </Link>
      )}
      renderInput={(params) => (
        <TextField
          className="headSearch-block"
          {...params}
          placeholder="Please enter last name, emp id or internation id"
          inputProps={{
            ...params.inputProps,
          }}
          sx={{
            borderRadius: isListOpen ? "20px 20px 0 0" : "50px",
            backgroundColor: Colors.white,
            "& fieldset": { border: "none" },
            boxShadow: "0px 0px 10px #E4ECFF",
            fontSize: "14px",
          }}
        />
      )}
      forcePopupIcon={true}
      autoHighlight={true}
      popupIcon={
        <SearchIcon
          sx={{
            backgroundColor: Colors.backgroundColor,
            color: "#013364",
            borderRadius: "50%",
            boxSizing: "content-box",
            padding: 1.2,
          }}
        />
      }
      PaperComponent={(props) => (
        <Paper
          sx={{
            borderRadius: "0 0 20px 20px",
            webkitScrollbar: { width: "4px" },

            "& .MuiAutocomplete-option[aria-selected='true']": {
              backgroundColor: "#F5F8FF",
            },
            "& .MuiAutocomplete-option:hover": {
              backgroundColor: "#F5F8FF",
            },
            "& .MuiAutocomplete-popper": {
              zIndex: "999 !important",
            },
            boxShadow: "0px 0px 10px #E4ECFF",
          }}
          {...props}
          // elevation={1}
        />
      )}
      onOpen={listOpened}
      onClose={listOpened}
      filterOptions={filterOptions}
    />
  );
};

export default Search;
