import React from 'react'
import Layout from '../components/Layout/Layout'

function PageNotFound() {
  return (
    <Layout>
        <h1>PageNotFound</h1>
    </Layout>
  )
}

export default PageNotFound