import React from "react";
import Layout from "../components/Layout/Layout";
import background from "../images/background.png";
import AccordionComp from "../components/AccordionComp";
import { Box } from "@mui/material";
import "../styles/HelpStyles.css";
import {
  pilotData,
  data,
  dcFactorData,
  dcData,
  RCCDataOne,
  RCCDataTwo,
  RCCDataThree,
  RCCDataFour,
} from "../components/common/HelpPageData";

const Help = () => {
  const tableStyle = {
    border: "1px solid #ccc", // Add border around the table
    borderCollapse: "collapse", // Collapse borders to prevent double borders
    width: "100%",
  };

  const cellStyle = {
    padding: "10px",
    borderBottom: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    position: "relative",
    
  };

  const rowStyle = {
    borderTop: "1px solid #ccc",
    backgroundColor: "#fff", // Add background color to alternate rows
  };
  const colStyle = {
    borderTop: "1px solid #ccc",
    backgroundColor: "#FFFFCC", // Add background color to alternate rows
  };
  return (
    <Layout backGroundImage={background}>
      <Box sx={{ width: "70%" }} className="help-center-main">
        <h6 className="mb-20">Help Centre</h6>
        <p>
          Everything you need to know about this project. Can't find the answer
          you are looking for? Please email us or call us
        </p>
        <div className="faq-box mt-40">
          <AccordionComp
            typography="Introduction"
            accordionDetails={
              <div>
                <p>
                  Welcome to the All-India Rating System.
                  <br />
                  This website provides pilots with the clear view of their
                  performances in various events.
                </p>
              </div>
            }
            panelNumber="1"
          />
          <AccordionComp
            typography="How to use this website"
            accordionDetails={
              <div>
                <p>
                  <strong>Pilots</strong>
                  <br />
                  Clicking on this link gives you the option to search for
                  pilots. You can search by last name of the pilot, local ID,
                  and International ID. After clicking the Search button, the
                  page will display all results matching the search criteria.
                  <br />
                  <br />
                  Clicking on a pilot's name will navigate you to the Pilot
                  Profile page, which showcases the pilot's profile details and
                  a link to their results in various events.
                </p>
                <p>
                  <br />
                  <strong>State/District</strong>
                  <br />
                  This link provides a list of all States and Districts within
                  the selected State. You can select a State and Districts to
                  view the list of pilots within that specific State/District.
                  The list includes ID, Gender, Nationality, Title, and Rating
                  details for each pilot.
                  <br />
                  <br />
                  Clicking on a pilot's name will lead you to the Pilot Profile
                  page, which shows their profile details and a link to their
                  results in various events.
                </p>
                <p>
                  <br />
                  <strong>Events</strong>
                  <br />
                  On this page, you can choose a State and District to see a
                  list of all events that occurred in that area. When you click
                  on an Event Name, you will be directed to the Event details
                  page. Here, you'll find a comprehensive list of pilots who
                  participated in the event, along with their results.
                  <br />
                  <br />
                  Clicking on a pilot's name will guide you to the Pilot Profile
                  page, where you can explore their profile details and access
                  links to their event results.
                </p>
                <p>
                  <br />
                  <strong>Top Performers</strong>
                  <br />
                  This page gives the list of pilots in the order of their
                  performance rating. You will be able to apply various filters
                  and search pilots based on those filters.
                  <br />
                  <br />
                  Various filters available are Flight Type, Gender, Age, State
                  and District.
                </p>
                <p>
                  <br />
                  <strong>Help</strong>
                  <br />
                  The Help page provides detailed instructions on how to
                  navigate this website using various options. Additionally,
                  this page contains links to resources that explain how ratings
                  are calculated and the criteria used for rating calculation.
                </p>
              </div>
            }
            panelNumber="2"
          />
          <AccordionComp
            typography="Frequency and Timelines for Publishing Ratings"
            accordionDetails={
              <div>
                <p>
                  New ratings will be published at the start of every month. It
                  will cover all the events and results reported before that
                  date.
                </p>
              </div>
            }
            panelNumber="3"
          />

          <AccordionComp
            typography="Rating Calculation"
            accordionDetails={
              <div>
                <p>
                  <strong>Prerequisite For Getting Initial Rating</strong>
                  <p>
                    <span className="circle">•</span>
                    <span className="text">
                      Pilots need to play in the officially rated events.
                    </span>
                  </p>
                  <p>
                    <span className="circle">•</span>
                    <span className="text">
                      Pilots need to be registered with AIRS before the event
                      starts.
                    </span>
                  </p>
                  <p>
                    <span className="circle">•</span>
                    <span className="text">
                      For the event to be Rated, it should be officially
                      registered for performance rating calculation with AIRS.
                    </span>
                  </p>
                  <p>
                    <span className="circle">•</span>
                    <span className="text">
                      Event can only be rated if they are completed under
                      acceptable conditions, with timers.
                    </span>
                  </p>
                  <p>
                    <span className="circle">•</span>
                    <span className="text">
                      A round will be rated if both pilots make at least one
                      move. Defaulted games, byes and absences will not be
                      rated.
                    </span>
                  </p>
                </p>
                <p>
                  <br />
                  <strong>
                    Initial Rating Calculations For Unrated Pilots
                  </strong>
                  <br />
                  <p>
                    <span className="circle">•</span>
                    <span className="text">
                      To get the performance rating, pilot must complete at
                      least 5 rounds. The round could be with rated pilot or
                      unrated pilot.
                    </span>
                  </p>
                  <p>
                    <span className="circle">•</span>
                    <span className="text">
                      If any pilot completes, only 3 rounds in that event, then
                      his performance rating is not displayed.
                    </span>
                  </p>
                  <p>
                    <span className="circle">•</span>
                    <span className="text">
                      However, all his performances in those rounds will be
                      considered for rating calculation. Later, whenever this
                      pilot participates in next events and completes 2 more
                      task (within 24 months of first round participated in),
                      then his performance rating will be calculated and
                      displayed in rating chart.
                    </span>
                  </p>
                  <p>
                    <span className="circle">•</span>
                    <span className="text">
                      Initial rating given to the pilot is 700 and based on the
                      number of rounds pilot wins or loses, his rating will be
                      adjusted (explained as below).
                    </span>
                  </p>
                  <p>
                    <span className="circle">•</span>
                    <span className="text">
                      Based on the performance pilot will either win, draw or
                      loose the round.
                    </span>
                  </p>
                  <p>
                    <span className="circle">•</span>
                    <span className="text">
                      For every win, pilot gets 1 point in the round, for Draw
                      0.5 and for loss pilot gets 0 points.
                    </span>
                  </p>
                </p>
                <p>
                  <br />
                  There are multiple scenarios possible while calculating the
                  rating for the pilot. For eg. Pilot has participated in 5
                  rounds. So could win 2.5 points or more than 2.5 points or
                  less than 2.5 points. At the time of initial ratings
                  calculations, this will be considered as a score of 50% points
                  scored, more than 50% points scored, or less than 50% points
                  scored respectively. Based on this the calculations vary which
                  is explained in detail below.
                  <br />
                </p>
                <p>
                  <br />
                  <strong>New Pilot Scores 50% Points</strong>
                  <br />
                  If the pilot has won 50% points in all his rounds (minimum 5
                  rounds), then the pilots will get average rating of his
                  opponents. E.g., If the pilot has completed 5 rounds and he
                  score 2.5 points, then his rating will be average of his
                  opponents rating.
                </p>
                <p>
                  <br />
                  In below example, Suresh is unrated pilot, and his initial
                  rating is assumed as 700.
                </p>
                <br />
                <div className="responsive-table">
                  <table className="pilot-score-table">
                    <thead>
                      <tr>
                        <th style={cellStyle}>Name</th>
                        <th style={cellStyle}>Rating</th>
                        <th style={cellStyle}>Score</th>
                        <th style={cellStyle}>Comment</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pilotData.map((pilot, index) => (
                        <tr
                          key={index}
                          style={{
                            ...rowStyle,
                            ...(index % 2 === 0
                              ? {}
                              : { backgroundColor: "#f2f2f2" }),
                          }}
                        >
                          <td style={cellStyle}>{pilot.name}</td>
                          <td style={cellStyle}>{pilot.rating}</td>
                          <td style={cellStyle}>{pilot.score}</td>
                          <td style={cellStyle}>{pilot.comment}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <p>
                  <br />
                  In this example, participated for one round each with Ramesh,
                  Kapil, Vinod, Payal and Kartik. Average of their rating is
                  1300 in this example. Suresh has scored 2.5 from 5 rounds. So,
                  he will get average of his opponents rating. So, Suresh will
                  now get starting rating of 1300.
                </p>
                <p>
                  <br />
                  <strong>New Pilot Scores More Than 50% Score</strong>
                  <br />
                  If the points scored more than 50%, then rating will be +20
                  points above average rating for every 0.5 points above the 50%
                  score.
                </p>
                <p>
                  <br />
                  Let’s see the below scores.
                  <br />
                </p>
                <div className="responsive-table">
                  <table className="pilot-score-table">
                    <thead>
                      <tr>
                        <th style={cellStyle}>Name</th>
                        <th style={cellStyle}>Rating</th>
                        <th style={cellStyle}>Score</th>
                        <th style={cellStyle}>Comment</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pilotData.map((pilot, index) => (
                        <tr
                          key={index}
                          style={{
                            ...rowStyle,
                            ...(index % 2 === 0
                              ? {}
                              : { backgroundColor: "#f2f2f2" }),
                          }}
                        >
                          <td style={cellStyle}>{pilot.name}</td>
                          <td style={cellStyle}>{pilot.rating}</td>
                          <td style={cellStyle}>
                            {pilot.name == "Kartik" ? "1" : pilot.score}
                          </td>
                          <td style={cellStyle}>{pilot.comment}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <p>
                  <br />
                  In this example Suresh has scored 3.5 from 5 rounds. 50% score
                  would have been 2.5. So, Suresh has scored 1 point more than
                  50% score. So, Suresh will get score of 1300 + 20 + 20 which
                  is 1340.
                </p>
                <p>
                  <br />
                  <strong>New Pilot Scores Less Than 50% Score</strong>
                  <br />
                  If points scored are less than 50%, then rating is reduced as
                  per Conversion table.
                  <br /> Let’s see below example where Suresh has score 2 points
                  from 5 rounds.
                  <br /> <strong>Rating Fator</strong> = Point score / No. of
                  Rounds = 2 / 5 = 0.4 <br /> Now Conversion Table shows -72 for
                  0.4. <br />
                  So, starting rating of Suresh will be average rating of
                  opponents minus 72 which is 1300 – 72 = 1228.
                </p>
                <p>
                  <br />
                  <strong>Conversion Table</strong>
                  <br />
                  <div className="responsive-table">
                    <table className="pilot-score-table" style={tableStyle}>
                      <thead>
                        <tr>
                          <th style={cellStyle}>p</th>
                          <th style={cellStyle}>dp</th>
                          <th style={cellStyle}>p</th>
                          <th style={cellStyle}>dp</th>
                          <th style={cellStyle}>p</th>
                          <th style={cellStyle}>dp</th>
                          <th style={cellStyle}>p</th>
                          <th style={cellStyle}>dp</th>
                          <th style={cellStyle}>p</th>
                          <th style={cellStyle}>dp</th>
                          <th style={cellStyle}>p</th>
                          <th style={cellStyle}>dp</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((row, index) => (
                          <tr
                            key={index}
                            style={{
                              ...rowStyle,
                              ...(index % 2 === 0
                                ? {}
                                : { backgroundColor: "#f2f2f2" }),
                            }}
                          >
                            {row.map((cell, cellIndex) => (
                              <td key={cellIndex} style={cellStyle}>
                                {cell}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </p>
                <p>
                  <br />
                  <strong>Rating Change Calculation For Rated Players</strong>
                  <br />
                  This section shows how the rating increase or decrease is
                  calculated for every round when the pilot has a published
                  rating. Rating change in any round is dependent on multiple
                  factors which are:
                  <br />
                  <br />
                  <p>
                    <span className="alpha">a)</span>
                    <span className="text-alpha">
                      Development Coefficient for the Pilot
                    </span>
                  </p>
                  <p>
                    <span className="alpha">b)</span>
                    <span className="text-alpha">
                      Rating difference between the pilot and his partner
                    </span>
                  </p>
                  <p>
                    <span className="alpha">c)</span>
                    <span className="text-alpha">
                      Score obtained in that round
                    </span>
                  </p>
                  <br />
                  Development Coefficient calculation is explained below.
                </p>
                <p>
                  <br />
                  <strong>
                    Development Coefficient (DC Factor) Calculation{" "}
                  </strong>
                  <br />
                  DC Factor is based on multiple factors which include Age of
                  the pilot, Current Rating and Number of Rounds completed in
                  that rating cycle.
                  <br />
                  <br />
                  These are the rules for DC Factor calculation:
                  <br />
                  <br />
                  <p>
                    <span className="alpha">a)</span>
                    <span className="text-alpha">
                      Players with age less than 18 and rating less than 2300,
                      will get DC = 40
                    </span>
                  </p>
                  <p>
                    <span className="alpha">b)</span>
                    <span className="text-alpha">
                      Players with age less than 18 and rating more than or
                      equal to 2300, will get DC = 20
                    </span>
                  </p>
                  <p>
                    <span className="alpha">c)</span>
                    <span className="text-alpha">
                      Players with age more than or equal to 18 and rating less
                      than 2400, will get DC = 20
                    </span>
                  </p>
                  <p>
                    <span className="alpha">d)</span>
                    <span className="text-alpha">
                      Players with age more than or equal to 18 and rating more
                      than or equal to 2400, will get DC = 10
                    </span>
                  </p>
                  <br />
                  <strong>Example</strong>
                  <br />
                  <div className="responsive-table">
                    <table className="pilot-score-table" style={tableStyle}>
                      <tbody>
                        {dcFactorData.map((row, rowIndex) => (
                          <tr
                            key={rowIndex}
                            style={{
                              ...rowStyle,
                              ...(rowIndex % 2 === 0
                                ? {}
                                : { backgroundColor: "#f2f2f2" }),
                            }}
                          >
                            {row.map((cell, cellIndex) => (
                              <td key={cellIndex} style={cellStyle}>
                                {cell}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <strong> Additional Rule: </strong>
                  DC calculated with the above rules is then evaluated with the
                  below rule and re-calculated if necessary. If the pilot has
                  participated in so many rounds during that rating cycle that
                  multiplication of DC and number of rounds participated in is
                  more than 700 then below rule is applied to re-calculated DC
                  to keep this multiplication less than 700. <br />
                  <br />
                  <p>
                    <span className="alpha">e)</span>
                    <span className="text-alpha">
                      DC calculated with above 4 rules is multiplied by number
                      of rounds participated by that pilot in that rating
                      period. If this multiplication is less than 700, then
                      final DC is same as the one calculated above.
                    </span>
                  </p>
                  <p>
                    <span className="alpha">f)</span>
                    <span className="text-alpha">
                      If this multiplication is more than 700, then Final DC =
                      (700 / number of rounds in that rating cycle)
                    </span>
                  </p>
                  <br />
                  <strong> Example: </strong>
                  In this example, number of rounds participated in is 19 and DC
                  value is 40. So the multiple is 19 * 40 = 760 which is more
                  than 700. Hence, the additional rule is applied to calculate
                  Final DC.
                  <br />
                  <br />
                  Final DC = Round (700 / 19, 0) = Round (36.84, 0) = 37
                  <br />
                  <br />
                  <div className="responsive-table">
                    <table className="pilot-score-table" style={tableStyle}>
                      <tbody>
                        {dcData.map((row, rowIndex) => (
                          <tr
                            key={rowIndex}
                            style={{
                              ...rowStyle,
                              ...(rowIndex % 2 === 0
                                ? {}
                                : { backgroundColor: "#f2f2f2" }),
                            }}
                          >
                            {row.map((cell, cellIndex) => (
                              <td key={cellIndex} style={cellStyle}>
                                {cellIndex === 1 && rowIndex === 2
                                  ? `${cell} `
                                  : cell}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </p>
                <p>
                  <br />
                  <strong>Rating Change Calculation</strong>
                  <br />
                  Pilots can get the score of 1 point for win, 0 points for loss
                  and 0.5 points for draw in the round.
                  <br />
                  <br />
                  Let assume the following:
                  <br />
                  <br />
                  <p>
                    <span className="alpha">a)</span>
                    <span className="text-alpha">
                      2 pilots (Ramesh and Suresh) participated in the round.
                      Ramesh has a rating of 1100 and Suresh has rating of 1300.
                    </span>
                  </p>
                  <p>
                    <span className="alpha">b)</span>
                    <span className="text-alpha">
                      Both Ramesh and Suresh have Development Coefficient (DC
                      factor) 20.
                    </span>
                  </p>
                  <p>
                    <span className="alpha">c)</span>
                    <span className="text-alpha">
                      Ramesh won the match, so he gets 1 points and Suresh gets
                      0 points.
                    </span>
                  </p>
                  <br />
                  The calculations for Ramesh will look as below.
                  <br />
                  <br />
                  <div className="table-calculation">
                    <div className="rcc-calculation1" style={{ flex: 1 }}>
                      <div className="responsive-table">
                        <table className="pilot-score-table" style={tableStyle}>
                          <tbody>
                            {RCCDataOne.map((row, rowIndex) => (
                              <tr
                                key={rowIndex}
                                style={{
                                  ...rowStyle,
                                  ...(rowIndex % 2 === 0
                                    ? {}
                                    : { backgroundColor: "#f2f2f2" }),
                                }}
                              >
                                {row.map((cell, cellIndex) => (
                                  <td key={cellIndex} style={cellStyle}>
                                    {cell}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      className="rcc-calculation2"
                      style={{ flex: 1 }}
                    >
                      <div className="responsive-table">
                        <table className="pilot-score-table" style={tableStyle}>
                          <tbody>
                            {RCCDataTwo.map((row, rowIndex) => (
                              <tr
                                key={rowIndex}
                                style={{
                                  ...rowStyle,
                                  ...(rowIndex % 2 === 0
                                    ? {}
                                    : { backgroundColor: "#f2f2f2" }),
                                }}
                              >
                                {row.map((cell, cellIndex) => (
                                  <td key={cellIndex} style={cellStyle}>
                                    {cell}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  In the above example, Ramesh has gained 15.2 rating points and
                  his new score is 1115.2. At the same time Suresh has lost 15.2
                  point and his new score will be 1300-15.2 = 1284.8
                  <br />
                  <br />
                  Let’s see another example as follows: <br />
                  <br />
                  <p>
                    <span className="alpha">a)</span>
                    <span className="text-alpha">
                      2 pilots (Ramesh and Suresh) participated in the round.
                      Ramesh has a rating of 1100 and Suresh has rating of 1300.
                    </span>
                  </p>
                  <p>
                    <span className="alpha">b)</span>
                    <span className="text-alpha">
                      Ramesh has Development Coefficient (DC factor) 40{" "}
                    </span>
                  </p>
                  <p>
                    <span className="alpha">c)</span>
                    <span className="text-alpha">
                      Suresh has Development Coefficient (DC factor) 20
                    </span>
                  </p>
                  <p>
                    <span className="alpha">d)</span>
                    <span className="text-alpha">
                      Ramesh and Suresh draw the match. So, both get 0.5 points.
                    </span>
                  </p>
                  <br />
                  <div className="table-calculation">
                    <div className="rcc-calculation1" style={{ flex: 1 }}>
                      <div className="responsive-table">
                        <table className="pilot-score-table" style={tableStyle}>
                          <tbody>
                            {RCCDataThree.map((row, rowIndex) => (
                              <tr
                                key={rowIndex}
                                style={{
                                  ...rowStyle,
                                  ...(rowIndex % 2 === 0
                                    ? {}
                                    : { backgroundColor: "#f2f2f2" }),
                                }}
                              >
                                {row.map((cell, cellIndex) => (
                                  <td key={cellIndex} style={cellStyle}>
                                    {cell}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="rcc-calculation2" style={{ flex: 1 }}>
                      <div className="responsive-table">
                        <table className="pilot-score-table" style={tableStyle}>
                          <tbody>
                            {RCCDataFour.map((row, rowIndex) => (
                              <tr
                                key={rowIndex}
                                style={{
                                  ...rowStyle,
                                  ...(rowIndex % 2 === 0
                                    ? {}
                                    : { backgroundColor: "#f2f2f2" }),
                                }}
                              >
                                {row.map((cell, cellIndex) => (
                                  <td key={cellIndex} style={cellStyle}>
                                    {cell}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  In this example, Ramesh gained 10.4 points. So, his new rating
                  will be 1110.4. <br />
                  <br />
                  Suresh lost 5.2 points. So, his new rating will be 1294.8.
                </p>
              </div>
            }
            panelNumber="4"
          />
          <AccordionComp
            typography="Ratings / Points Correction"
            accordionDetails={
              <div>
                <p>
                  Pilots will be able to claim for the correction of points and
                  in turn rating if at all in any of the previous events the
                  points are incorrectly entered. Pilots will be able to claim
                  for correction for events upto 4 months after the results for
                  that event are published. For eg. If the event has happened on
                  15th Jan 2023, so the results for this event are published on
                  31st Jan 2023, then pilots will be able to apply for
                  correction of results in Feb, March, April and May 2023.
                  Beyond these 4 months, pilots will not be able to apply for
                  correction of results.
                  <br />
                  <br /> Based on the correction of results, the points for that
                  pilot and his co-pilot will be updated. And the ratings will
                  be recalculated for all the pilots. Updated rating will be
                  re-published at the end of the month.
                  <br />
                  <br /> Pilots will need to send communication to the
                  respective manager for that event to get the results
                  corrected.
                </p>
              </div>
            }
            panelNumber="3"
          />
          <AccordionComp
            typography="Player Identification"
            accordionDetails={
              <div>
                <p>
                  <br />
                  All players will be identified using their All India ID which
                  will be unique for the players, and these will be in
                  conformation with the All-India Szystems rules and guidelines.
                </p>
              </div>
            }
            panelNumber="3"
          />
        </div>
      </Box>
    </Layout>
  );
};

export default Help;
