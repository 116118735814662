import { Box, Button, Grid } from "@mui/material";
import Layout from "../components/Layout/Layout";
import { Link, useParams } from "react-router-dom";
import Advertisement from "../components/Advertisement";
import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  getPilotByEmpId,
  getEventResultsByEmpId,
} from "../services/APIService";
import { useState, useEffect } from "react";
import Colors from "../styles/theme/Colors";
import data from "../components/common/data.json";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import CustomNoRowsOverlay from "../components/CustomNoRowsOverlay";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const PilotProfile = () => {
  const { emp_id } = useParams();

  const [pilotData, setPilotData] = useState([]);
  const [pilotMembership, setpilotMembership] = useState([]);
  const [pilotNationality, setpilotNationality] = useState([]);
  const [pilotState, setpilotState] = useState([]);
  const [pilotDistrict, setpilotDistrict] = useState([]);
  const [ratingHistory, setRatingHistory] = useState([]);
  const [offset, setOffset] = useState(0);
  const [isNext, setIsNext] = useState(true);
  const [nextClicked, setNextClicked] = useState(false);
  const perPage = 10;

  useEffect(() => {
    getPilotByEmpId(emp_id).then((pilot) => {
      setPilotData(pilot.pilotData);
      setpilotMembership(pilot.membership);
      setpilotNationality(pilot.nationality);
      setpilotState(pilot.state);
      setpilotDistrict(pilot.district);
    });
  }, [emp_id]);

  useEffect(() => {
    if (pilotData.is_experienced_longFlight || pilotData.is_experienced_longSimulation || pilotData.is_experienced_mediumFlight || pilotData.is_experienced_mediumSimulation || pilotData.is_experienced_shortFlight || pilotData.is_experienced_shortSimulation) {
      getEventResultsByEmpId(emp_id, offset, perPage).then((response) => {
        if (response.rating_history.length === 0) {
          setIsNext(false);
          // console.log(response.rating_history);
        } else {
          setIsNext(true);
        }
        setRatingHistory(response.rating_history);
      });
    }
  }, [emp_id, pilotData, offset]);

  const ratingColumns = (pilotData.is_experienced_longFlight || pilotData.is_experienced_longSimulation || pilotData.is_experienced_mediumFlight || pilotData.is_experienced_mediumSimulation || pilotData.is_experienced_shortFlight || pilotData.is_experienced_shortSimulation)
    ? [
        { field: "id", headerName: "ID", headerAlign: "center", flex: 1 },
        {
          field: "month_year",
          headerName: "Month-Year",
          headerAlign: "center",
          align: "center",
          flex: 1,
          headerClassName:
            "grid-header border-right fw-700 font-size-14 label-color text-center",
        },
        {
          field: "f_long_flight",
          headerName: "Long Flight",
          headerAlign: "center",
          align: "center",
          flex: 1,
          headerClassName:
            "grid-header fw-700 font-size-14 label-color text-center",
        },
        {
          field: "f_medium_range",
          headerName: "Medium Range",
          headerAlign: "center",
          align: "center",
          flex: 1,
          headerClassName:
            "grid-header fw-700 font-size-14 label-color text-center",
        },
        {
          field: "f_short_flight",
          headerName: "Short Flight",
          headerAlign: "center",
          align: "center",
          flex: 1,
          headerClassName:
            "grid-header border-right fw-700 font-size-14 label-color text-center",
        },
        {
          field: "s_long_flight",
          headerName: "Long Flight",
          headerAlign: "center",
          align: "center",
          flex: 1,
          headerClassName:
            "grid-header fw-700 font-size-14 label-color text-center",
        },
        {
          field: "s_medium_range",
          headerName: "Medium Range",
          headerAlign: "center",
          align: "center",
          flex: 1,
          headerClassName:
            "grid-header fw-700 font-size-14 label-color text-center",
        },
        {
          field: "s_short_flight",
          headerName: "Short Flight",
          headerAlign: "center",
          align: "center",
          flex: 1,
          headerClassName:
            "grid-header fw-700 font-size-14 label-color text-center",
        },
      ]
    : [];

  // const ratingRows = pilotData.is_experienced
  //   ? [
  //       {
  //         id: 1,
  //         month_year: "April-2023",
  //         f_long_flight: 1020,
  //         f_medium_range: 900,
  //         f_short_flight: 874,
  //         s_long_flight: 1125,
  //         s_medium_range: 1024,
  //         s_short_flight: 998,
  //       },
  //       {
  //         id: 2,
  //         month_year: "March-2023",
  //         f_long_flight: 1260,
  //         f_medium_range: 940,
  //         f_short_flight: 800,
  //         s_long_flight: 1260,
  //         s_medium_range: 1002,
  //         s_short_flight: 824,
  //       },
  //       {
  //         id: 3,
  //         month_year: "Februar-2023",
  //         f_long_flight: 1100,
  //         f_medium_range: 925,
  //         f_short_flight: 825,
  //         s_long_flight: 1252,
  //         s_medium_range: 1050,
  //         s_short_flight: 900,
  //       },
  //     ]
  //   : [];

  const ratingColumnGroupingModel = (pilotData.is_experienced_longFlight || pilotData.is_experienced_longSimulation || pilotData.is_experienced_mediumFlight || pilotData.is_experienced_mediumSimulation || pilotData.is_experienced_shortFlight || pilotData.is_experienced_shortSimulation) 
    ? [
        {
          groupId: "Rating History",
          children: [
            { field: "month_year" },
            {
              groupId: "Flight Rating",
              children: [
                { field: "f_long_flight" },
                { field: "f_medium_range" },
                { field: "f_short_flight" },
              ],
              headerAlign: "center",
              headerClassName:
                "grid-sub-header fw-700 font-size-15 label-color text-center border-right border-left",
            },
            {
              groupId: "Simulation Rating",
              children: [
                { field: "s_long_flight" },
                { field: "s_medium_range" },
                { field: "s_short_flight" },
              ],
              headerAlign: "center",
              headerClassName:
                "grid-sub-header fw-700 font-size-15 label-color text-center",
            },
          ],
          headerAlign: "center",
          headerClassName:
            "grid-main-header font-size-18 label-color text-center",
        },
      ]
    : [];

  const handleNextButton = () => {
    setNextClicked(true);
    setOffset(offset + perPage);
  };

  const handlePreviousButton = () => {
    setOffset(offset - perPage);
  };

  return (
    <Layout>
      <Box sx={{ width: "95%" }}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            display={{ xs: "none", sm: "none", md: "block", lg: "block" }}
          >
            <Advertisement />
          </Grid>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <Paper
              sx={{
                p: 2,
                flexGrow: 1,
                borderRadius: "7px",
                boxShadow: "0px 0px 15px 0px rgba(228, 236, 255, 0.74);",
                marginBottom: "30px",
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark" ? "#1A2027" : "#fff",
              }}
            >
              <Grid container spacing={1} className="profile-table">
                <Grid item xs={12}>
                  <Box className="float-right fw-400 font-size-15 status-info mb-15">
                    Status:{" "}
                    <span className="active-state font-size-15 fw-600">
                      {data.status[pilotData.status]}
                    </span>{" "}
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  marginBottom={{ xs: "10px", sm: "10px" }}
                  className="pt-0"
                >
                  <Box className="pilotDpBlock">
                    <Img
                      alt="complex"
                      className="w-100 h-100"
                      src={
                        pilotData.photo
                          ? process.env.REACT_APP_BASE_URL +
                            "/images/pilots/" +
                            pilotData.photo
                          : "/user-icon.jpg"
                      }

                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src="/user-icon.jpg";
                      }}
                    />
                  </Box>
                  <h4 className="fw-700 font-size-15 label-color text-uppercase text-center">
                    {pilotData.name} {pilotData.mname} {pilotData.lname}
                  </h4>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={10}
                  md={10}
                  className="profile-data-block"
                >
                  <Grid container item spacing={1} className="profile-data-row">
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={2}
                      className="profile-labels profile-box"
                    >
                      Emp ID
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={2}
                      className="profile-data profile-box emp-id"
                    >
                      {pilotData.emp_id}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={2}
                      className="profile-labels profile-box"
                    >
                      Int ID
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={2}
                      className="profile-data profile-box int-id"
                    >
                      <a
                        href={
                          "https://www.google.com/profile/" + pilotData.int_id
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                       
                        className="link"
                      >
                        {pilotData.int_id}
                      </a>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={2}
                      className="profile-labels profile-box"
                    >
                      Title
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={2}
                      className="profile-data profile-box"
                    >
                      {pilotData.title}
                    </Grid>
                  </Grid>
                  <Grid container item spacing={1} className="profile-data-row">
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={2}
                      className="profile-labels profile-box"
                    >
                      Membership
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={2}
                      className={`profile-data profile-box ${
                        pilotMembership.name
                          ? pilotMembership.name.toLowerCase()
                          : ""
                      }-membr`}
                    >
                      {pilotMembership.name}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={2}
                      className="profile-labels profile-box"
                    >
                      Gender
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={2}
                      className="profile-data profile-box letter-capitalize"
                    >
                      {pilotData.gender}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={2}
                      className="profile-labels profile-box"
                    >
                      Year of Birth
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={2}
                      className="profile-data profile-box"
                    >
                      {pilotData.yob}
                    </Grid>
                  </Grid>
                  <Grid container item spacing={1} className="profile-data-row">
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={2}
                      className="profile-labels profile-box"
                    >
                      Nationality
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={2}
                      className="profile-data profile-box"
                    >
                      {pilotNationality.name}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={2}
                      className="profile-labels profile-box"
                    >
                      State
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={2}
                      className="profile-data profile-box"
                    >
                      {pilotState.name}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={2}
                      className="profile-labels profile-box"
                    >
                      District
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={2}
                      className="profile-data profile-box"
                    >
                      {pilotDistrict.name}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>

            {(pilotData.is_experienced_longFlight === 1 || pilotData.is_experienced_longSimulation === 1 || pilotData.is_experienced_mediumFlight === 1 || pilotData.is_experienced_mediumSimulation === 1 || pilotData.is_experienced_shortFlight === 1 || pilotData.is_experienced_shortSimulation === 1) && (
              <div
                style={{
                  width: "100%",
                  height: ratingHistory.length === 0 ? "" : "",
                }}
              >
                <DataGrid
                  className="dataGridProfile"
                  experimentalFeatures={{ columnGrouping: true }}
                  rows={ratingHistory}
                  columns={ratingColumns}
                  components={{
                    noRowsOverlay: CustomNoRowsOverlay,
                  }}
                  disableRowSelectionOnClick
                  columnGroupingModel={ratingColumnGroupingModel}
                  hideFooterPagination={true}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        id: false,
                      },
                    },
                  }}
                  rowHeight={52}
                  columnHeaderHeight={50}
                  sx={{
                    "& > .MuiDataGrid-columnSeparator": {
                      visibility: "hidden",
                    },
                  }}
                />
                <div className="pagination-view-main">
                  {(ratingHistory.length > 0 || nextClicked) && (
                    <div className="view-results">
                      <Link to={{ pathname: "/results/" + pilotData.emp_id }}>
                        <Button
                          color="inherit"
                          sx={{
                            backgroundColor: Colors.secondary,
                            borderRadius: "5rem",
                            textTransform: "none",
                            color: "#ffffff",
                            padding: {
                              lg: "5px 20px",
                              sm: "3px 10px",
                              xs: "5px 25px",
                            },
                            fontSize: { lg: "14px", sm: "10px", xs: "14px" },
                            maxWidth: { xl: "150px" },
                            marginLeft: "0",
                            position: {
                              lg: "static",
                              sm: "static",
                              xs: "absolute",
                              right: "80px",
                            },
                            flexGrow: 1,
                            fontWeight: 400,
                          }}
                        >
                          View All Results
                        </Button>
                      </Link>
                    </div>
                  )}

                  {(ratingHistory.length > 0 || nextClicked) && (
                    <div className="next-prev-btns">
                      <Button
                        disabled={offset === 0}
                        onClick={handlePreviousButton}
                      >
                        Previous
                      </Button>
                      <Button disabled={!isNext} onClick={handleNextButton}>
                        Next
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default PilotProfile;
