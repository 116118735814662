const pilotData = [
  {
    name: "Suresh",
    rating: 700,
    score: "New",
    comment:
      "Suresh is assumed to have 700 rating initially for rating change calculation of opponents.",
  },
  { name: "Ramesh", rating: 1100, score: 1, comment: "" },
  { name: "Kapil", rating: 1200, score: 0, comment: "" },
  { name: "Vinod", rating: 1300, score: 1, comment: "" },
  { name: "Payal", rating: 1400, score: 0.5, comment: "" },
  { name: "Kartik", rating: 1500, score: 0, comment: "" },
];
const data = [
  [1, 800, 0.83, 273, 0.66, 117, 0.49, -7, 0.32, -133, 0.15, -296],
  [0.99, 677, 0.82, 262, 0.65, 110, 0.48, -14, 0.31, -141, 0.14, -309],
  [0.98, 589, 0.81, 251, 0.64, 102, 0.47, -21, 0.3, -149, 0.13, -322],
  [0.97, 538, 0.8, 240, 0.63, 95, 0.46, -29, 0.29, -158, 0.12, -336],
  [0.96, 501, 0.79, 230, 0.62, 87, 0.45, -36, 0.28, -166, 0.11, -351],
  [0.95, 470, 0.78, 220, 0.61, 80, 0.44, -43, 0.27, -175, 0.1, -366],
  [0.94, 444, 0.77, 211, 0.6, 72, 0.43, -50, 0.26, -184, 0.09, -383],
  [0.93, 422, 0.76, 202, 0.59, 65, 0.42, -57, 0.25, -193, 0.08, -401],
  [0.92, 401, 0.75, 193, 0.58, 57, 0.41, -65, 0.24, -202, 0.07, -422],
  [0.91, 383, 0.74, 184, 0.57, 50, 0.4, -72, 0.23, -211, 0.06, -444],
  [0.9, 366, 0.73, 175, 0.56, 43, 0.39, -80, 0.22, -220, 0.05, -470],
  [0.89, 351, 0.72, 166, 0.55, 36, 0.38, -87, 0.21, -230, 0.04, -501],
  [0.88, 336, 0.71, 158, 0.54, 29, 0.37, -95, 0.2, -240, 0.03, -538],
  [0.87, 322, 0.7, 149, 0.53, 21, 0.36, -102, 0.19, -251, 0.02, -589],
  [0.86, 309, 0.69, 141, 0.52, 14, 0.35, -110, 0.18, -262, 0.01, -677],
  [0.85, 296, 0.68, 133, 0.51, 7, 0.34, -117, 0.17, -273, 0, -800],
  [0.84, 284, 0.67, 125, 0.5, 0, 0.33, -125, 0.16, -284],
];
const dcFactorData = [
  ["Development Coefficient (DC)", "", "Formula"],
  ["Age (A)", "10", ""],
  [
    "No. Of Tasks Completed (N)",
    "5",
    "No. of matches played in that rating cycle",
  ],
  ["Current Rating (CR)", "1300", ""],
  ["DC Value (DC)", "40", "=IF(A<18,IF(CR<2300,40,20),IF(CR<2400,20,10))"],
  ["Final DC Value (FDC)", "40", "=IF(C4*DC<700,DC,ROUND(700/C4,0))"],
];
const dcData = [
  ["Development Coefficient (DC)", "", "Formula"],
  ["Age (A)", "16", ""],
  ["No. Of Tasks Completed (N)", "19", ""],
  ["Current Rating (CR)", "900", ""],
  ["DC Value (DC)", "40", "=IF(A<18,IF(CR<2300,40,20),IF(CR<2400,20,10))"],
  ["Final DC Value (FDC)", "37", "=IF(C4*DC<700,DC,ROUND(700/C4,0))"],
];
const RCCDataOne = [
  ["A) Ramesh", "1100"],
  ["RA-RB", "200"],
  ["(RA-RB)/400", "0.5"],
  ["10^(RA-RB)/400", "3.17"],
  ["ES=1/(1+10^((RA-RB)/400))", "0.24"],
  ["Score (S)", "1"],
  ["DC factor", "20"],
  ["R increase (DC*(S - ES))", "15.2"],
  ["New Rating (R + DC*(S - ES))", "1115.2"],
];
const RCCDataTwo = [
  ["B) Suresh", "1300"],
  ["RA-RB", "-200"],
  ["(RA-RB)/400", "-0.5"],
  ["10^(RA-RB)/400", "0.32"],
  ["ES=1/(1+10^((RA-RB)/400))", "0.76"],
  ["Score (S)", "0"],
  ["DC factor", "20"],
  ["R increase (DC*(S - ES))", "-15.2"],
  ["New Rating (R + DC*(S - ES))", "1284.8"],
];
const RCCDataThree = [
  ["A) Ramesh", "1100"],
  ["RA-RB", "200"],
  ["(RA-RB)/400", "0.5"],
  ["10^(RA-RB)/400", "3.17"],
  ["ES=1/(1+10^((RA-RB)/400))", "0.24"],
  ["Score (S)", "0.5"],
  ["DC factor", "40"],
  ["R increase (DC*(S - ES))", "10.4"],
  ["New Rating (R + DC*(S - ES))", "1110.4"],
];
const RCCDataFour = [
  ["B) Suresh", "1300"],
  ["RA-RB", "-200"],
  ["(RA-RB)/400", "-0.5"],
  ["10^(RA-RB)/400", "0.32"],
  ["ES=1/(1+10^((RA-RB)/400))", "0.76"],
  ["Score (S)", "0.5"],
  ["DC factor", "20"],
  ["R increase (DC*(S - ES))", "-5.2"],
  ["New Rating (R + DC*(S - ES))", "1294.8"],
];
export {
  pilotData,
  data,
  dcFactorData,
  dcData,
  RCCDataOne,
  RCCDataTwo,
  RCCDataThree,
  RCCDataFour,
};
