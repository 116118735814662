import React from "react";
import Layout from "../components/Layout/Layout";
import background from "../images/background.png";
import { Box } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Layout backGroundImage={background}>
      <Box sx={{ width: "70%" }} className="privacy-policy">
        <h4 className="mb-10">Privacy Policy and Terms Of Use</h4>
        <p>
          This website provides rating service to AIRS which helps in high
          performing pilots at nations, state and district level. For this
          purpose, pilots details (like First name, Middle name, Last name,
          Nationality, State, District, AIRS Title, International ID, Year of
          Birth, Gender and Photo) are collected and processed.
        </p>
        <h6 className="mb-10 mt-20">Data Collection</h6>
        <p>
          Data is collected in a very fair and transparent manner. Data is collected in multiple ways.
        </p>
        <br></br>
        <div className="ml-30">
          <p>
            -	Data is imported from AIRS website
          </p>
          <p>
            - Data is inserted by State/District/Local federations or teams for
            any pilot who is not already registered with AIRS.
          </p>
          <p>- Data collected is in accordance with AIRS. </p>
          <p>- No unnecessary data is collected from pilots.</p>
          <p>
            - All the data collected is used for the purpose of displaying their
            ranking at various levels like national level, state level and
            district level.
          </p>
          <p>
            - This data is collected for pilots: First name, Middle name, Last
            name, Nationality, State, District, AIRS Title, International ID,
            Year of Birth, Gender and Photo.
          </p>
        </div>

        <h6 className="mb-10 mt-20">Data Collection Refusal</h6>
        <p>
          In case, any individual is uncomfortable with data collected, the
          purposes mentioned in below section (Purpose of Data Collection) will
          not be possible to be achieved. Hence, the pilots details on
          performance and ranking will not be displayed.
        </p>
        <h6 className="mb-10 mt-20">Purpose of Data Collection</h6>
        <p>
          AIRS collects data in fair and transparent manner for the purposes of
          internal functioning of the rating system. This rating system serves
          various purposes:
        </p>
        <br></br>
        <div className="ml-30">
          <p>
            - To measure the level of strength of the pilots and display ranking
            based on this.
          </p>
          <p>
            - This will help National, State and District bodies to identify
            high performing pilots.
          </p>
        </div>
        <h6 className="mb-10 mt-20">How AIRS Processes Data</h6>
        <p>AIRS process data in automatic and manual way. </p>
        <br></br>
        <div className="ml-30">
          <p>
            - Data is automatically processed in order to calculate performance
            rating, ranking, activity or inactivity of pilots, display title of
            pilots, display raking at National, State and District level.{" "}
          </p>
          <p>
            - Manual process includes inserting data for new pilot registration,
            updating/deleting details for single or multiple pilots, downloading
            or exporting list of pilots and their rating list.{" "}
          </p>
          <p>
            - Manual process also includes any data search or enquiry by any
            visitor on the website.
          </p>
        </div>
        <h6 className="mb-10 mt-20">Data Storage and Duration of Storage</h6>
        <div className="ml-30">
          <p>
            - Data is stored only in electronic format. No data is stored in
            paper format.
          </p>
          <p>
            - Data is stored on the cloud servers of agreed cloud storage
            service provider.
          </p>
          <p>
            - Data will be stored while the pilot is active and also beyond
            his/her activity period for the purpose of proper functioning of
            this system for the purposes mentioned above.
          </p>
          <p>
            - Personal Data for any specific pilot will be removed from the
            system upon request, however, the records of his/her participation
            will be retained for the purpose of proper functioning of this
            system for the purposes mentioned above.
          </p>
        </div>
        <h6 className="mb-10 mt-20">Data Distribution/Transfer and Publication</h6>
        <div className="ml-30">
          <p>
            - Data collected in AIRS system will not be distributed or
            transferred to any third parties, company, body, or any other
            federation for any commercial purpose.
          </p>
          <p>
            - All the data collected and processed and displayed as mentioned
            this document.
          </p>
          <p>
            - This data is collected and published for pilots: First name,
            Middle name, Last name, Nationality, State, District, AIRS Title,
            International ID, Year of Birth, Gender and Photo.
          </p>
          <p>
            - Any visitor can also search for list of pilots and their ratings
            and ranking based on various criteria available in the website.
          </p>
        </div>

        <h6 className="mb-10 mt-20">Ads Content on Website</h6>
        <p>
          We would like to point out that to all users that they use all online ads/offers on the website at their own risk. We assume no responsibility of the topicality, correctness, completeness, or quantity of information provided. All ads/offers are non-binding to all the users. Liability claims relating to material or immaterial damage caused by use or non-use of the information provided are fundamentally excluded.
        </p>

        <h6 className="mb-10 mt-20">References and Links</h6>
        <p>
          We exclude ourselves from liability for any third party content that is referred to via any direct or indirect reference (links), as there is no influence whatsoever on the design or content.
        </p>

        <h6 className="mb-10 mt-20">Copyright and trademark law</h6>
        <p className="mb-10">
          We strive to respect the copyrights of the texts, images, graphics or other documents used in all publications/ads/contents. All brand names and trademarks mentioned within the website, and possibly protected by third parties are subject without restriction to the provisions of the applicable trademark law and the ownership rights of the respective registered owners. The conclusion that trademarks are not protected by third-party rights should not be drawn in any case.
        </p>
        <p>
          The copyright for published objects created by myself remains with the author of the pages. Reproduction or use in other electronic or printed publications is not permitted without my express consent.
        </p>
      </Box>
    </Layout>
  );
};

export default PrivacyPolicy;
