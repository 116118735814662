import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../../styles/LayoutStyles.css";
import Colors from "../../styles/theme/Colors"

const Layout = ({ children, backGroundImage = null }) => {
  return (
    <>
      <Header />
        <div
          className="app-container"
          style={{ backgroundImage: `url(${backGroundImage})`, backgroundColor: backGroundImage == null ? Colors.tableEvenRow : Colors.white, padding: backGroundImage == null ? "30px 0" : 0 }}
        >
          {children}
        </div>
      <Footer />
    </>
  );
};

export default Layout;
