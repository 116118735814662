import Layout from "../components/Layout/Layout";
import { Box, Grid } from "@mui/material";
import Advertisement from "../components/Advertisement";
import Paper from "@mui/material/Paper";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import {
  getPilotByEmpId,
  getPilotMonthlyResults,
} from "../services/APIService";
import { useParams } from "react-router-dom";
import data from "../components/common/data.json";
import DataTable from "../components/DataTable";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Colors from "../styles/theme/Colors";
import { Select, MenuItem, InputLabel } from "@mui/material";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const header = [
  {
    id: "date",
    label: "Date",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "is_leader",
    label: "Leader/Follower",
    width: 50,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "score",
    label: "Score",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "rating_inc_dec",
    label: "Rating +/-",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "rating",
    label: "Rating",
    width: 130,
    numeric: true,
    disablePadding: false,
  },
  {
    id: "partner",
    label: "Partner",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "partner_rating",
    label: "Partner Rating",
    width: 130,
    numeric: true,
    disablePadding: false,
  },
  {
    id: "partner_emp_id",
    label: "Partner Emp ID",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "partner_nationality",
    label: "Partner Nationality",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "event_name",
    label: "Event Name",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
];

const PilotResults = () => {
  const { emp_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [pilotData, setPilotData] = useState([]);
  const [pilotMembership, setpilotMembership] = useState([]);
  const [pilotNationality, setpilotNationality] = useState([]);
  const [pilotState, setpilotState] = useState([]);
  const [pilotDistrict, setpilotDistrict] = useState([]);
  const [pilotMonthlyResult, setPilotMonthlyResult] = useState([]);
  // const [lastPage, setlastPage] = useState(0);
  const [currentPage, setcurrentPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [eventType, setEventType] = useState("1");
  const [eventRange, setEventRange] = useState("3");
  const [eventYear, setEventYear] = useState("All");
  const [eventMonth, setEventMonth] = useState(0);
  const perPage = 10;
  const [lastResult, setLastResult] = useState(false);
  const [selectedYear, setSelectedYear] = useState("All");

  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 2022 },
    (_, index) => currentYear - index
  );

  const months = [
    { id: 0, name: "All" },
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];
  const [selectedMonth, setSelectedMonth] = useState(0);

  useEffect(() => {
    setLoading(true);
    getPilotByEmpId(emp_id).then((pilot) => {
      setLoading(false);
      setPilotData(pilot.pilotData);
      setpilotMembership(pilot.membership);
      setpilotNationality(pilot.nationality);
      setpilotState(pilot.state);
      setpilotDistrict(pilot.district);
    });

    // console.log("currentPage", currentPage);
    if (
      (!lastResult && prevPage !== currentPage) ||
      eventType > 0 ||
      eventRange > 0 ||
      eventYear !== "" ||
      eventMonth
    ) {
      fetchResults();
    }
  }, [
    emp_id,
    currentPage,
    eventType,
    eventRange,
    lastResult,
    eventYear,
    eventMonth,
  ]);

  function handleTypeChange(newType) {
    setEventType(newType);
    setcurrentPage(1); // Reset the current page to 1
    setPrevPage(0);
    setLastResult(false);
  }

  function handleRangeChange(newRange) {
    setEventRange(newRange);
    setcurrentPage(1); // Reset the current page to 1
    setPrevPage(0);
    setLastResult(false);
  }

  function fetchResults() {
    getPilotMonthlyResults(
      emp_id,
      eventType,
      eventRange,
      eventYear,
      eventMonth,
      currentPage,
      perPage
    ).then((results) => {
      if (results) {
        if (currentPage === 1) {
          setPilotMonthlyResult(results.event_pilot_results);
        } else {
          setPilotMonthlyResult((pilotMonthlyResult) => [
            ...pilotMonthlyResult,
            ...results.event_pilot_results,
          ]);
          // var joined = pilotMonthlyResult.concat(results.event_pilot_results);
          // setPilotMonthlyResult(joined);
        }
        setPrevPage(currentPage);

        // setlastPage(results.pagination.last_page);
        // console.log("currentPage", currentPage);
        if (results.pagination.last_page === currentPage) {
          setLastResult(true);
          // console.log("lastResult", lastResult);
        }
      }
    });
  }

  useEffect(() => {
    // Attach event listener to the scrollable container
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;
      if (
        scrollTop + clientHeight >= scrollHeight &&
        !lastResult &&
        pilotMonthlyResult.length > 0
      ) {
        // Load more data when the user has scrolled to near the bottom of the container
        setcurrentPage((prevPage) => prevPage + 1);
      }
    };
    // console.log("pilotMonthlyResult.length", pilotMonthlyResult.length);
    // Add event listener to the scrollable container
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [pilotMonthlyResult, lastResult]);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setEventYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(Number(event.target.value));
    setEventMonth(Number(event.target.value));
  };

  return (
    <Layout>
      <Box sx={{ width: "95%" }}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            display={{ xs: "none", sm: "none", md: "block", lg: "block" }}
          >
            <Advertisement />
          </Grid>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <Paper
              sx={{
                p: 2,
                flexGrow: 1,
                borderRadius: "7px",
                boxShadow: "0px 0px 15px 0px rgba(228, 236, 255, 0.74);",
                marginBottom: "0px",
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark" ? "#1A2027" : "#fff",
              }}
            >
              <Grid container spacing={1} className="profile-table">
                <Grid item xs={12}>
                  <Box className="float-right fw-400 font-size-15 status-info mb-15">
                    Status:{" "}
                    <span className="active-state font-size-15 fw-600">
                      {data.status[pilotData.status]}
                    </span>{" "}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={2} className="pt-0">
                  <Box className="pilotDpBlock">
                    <Img
                      alt="complex"
                      className="w-100 h-100"
                      src={
                        pilotData.photo
                          ? process.env.REACT_APP_BASE_URL +
                            "/images/pilots/" +
                            pilotData.photo
                          : "/user-icon.jpg"
                      }
                    />
                  </Box>
                  <h4 className="fw-700 font-size-15 label-color text-uppercase text-center">
                    {pilotData.name} {pilotData.mname} {pilotData.lname}
                  </h4>
                </Grid>
                <Grid item xs={12} sm={9} className="profile-data-block">
                  <Grid container item spacing={1} className="profile-data-row">
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-labels profile-box"
                    >
                      Emp ID
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-data profile-box emp-id"
                    >
                      {pilotData.emp_id}
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-labels profile-box"
                    >
                      Int ID
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-data profile-box int-id"
                    >
                      <a
                        href={
                          "https://www.google.com/profile/" + pilotData.int_id
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link"
                      >
                        {pilotData.int_id}
                      </a>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-labels profile-box"
                    >
                      Title
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-data profile-box"
                    >
                      {pilotData.title}
                    </Grid>
                  </Grid>
                  <Grid container item spacing={1} className="profile-data-row">
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-labels profile-box"
                    >
                      Membership
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={2}
                      className={`profile-data profile-box ${
                        pilotMembership.name
                          ? pilotMembership.name.toLowerCase()
                          : ""
                      }-membr`}
                    >
                      {pilotMembership.name}
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-labels profile-box"
                    >
                      Gender
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-data profile-box letter-capitalize"
                    >
                      {pilotData.gender}
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-labels profile-box"
                    >
                      Year of Birth
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-data profile-box"
                    >
                      {pilotData.yob}
                    </Grid>
                  </Grid>
                  <Grid container item spacing={1} className="profile-data-row">
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-labels profile-box"
                    >
                      Nationality
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-data profile-box"
                    >
                      {pilotNationality.name}
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-labels profile-box"
                    >
                      State
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-data profile-box"
                    >
                      {pilotState.name}
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-labels profile-box"
                    >
                      District
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-data profile-box"
                    >
                      {pilotDistrict.name}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={2} className="pt-0"></Grid>

                <Grid className="result-filter-main" item xs={12} sm={9}>
                  <Grid
                    container
                    spacing={2}
                    className="justifyContentBetween custom-select-width custom-pl result-filter-inner mt-0"
                  >
                    <Grid item xs={6} sm={4}>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={eventType}
                        onChange={(e) => handleTypeChange(e.target.value)}
                      >
                        {/* <FormControlLabel
                      value="0"
                      className="radio-button"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: Colors.secondary,
                            },
                          }}
                        />
                      }
                      label="All"
                    /> */}
                        <FormControlLabel
                          value="1"
                          className="radio-button"
                          control={
                            <Radio
                              sx={{
                                "&.Mui-checked": {
                                  color: Colors.secondary,
                                },
                              }}
                            />
                          }
                          label="Flight"
                        />
                        <FormControlLabel
                          value="2"
                          className="radio-button"
                          control={
                            <Radio
                              sx={{
                                "&.Mui-checked": {
                                  color: Colors.secondary,
                                },
                              }}
                            />
                          }
                          label="Simulation"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={6} sm={4} className="pl-0">
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={eventRange}
                        onChange={(e) => handleRangeChange(e.target.value)}
                      >
                        {/* <FormControlLabel
                      value="0"
                      className="radio-button"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: Colors.secondary,
                            },
                          }}
                        />
                      }
                      label="All"
                    /> */}
                        <FormControlLabel
                          value="3"
                          className="radio-button"
                          control={
                            <Radio
                              sx={{
                                "&.Mui-checked": {
                                  color: Colors.secondary,
                                },
                              }}
                            />
                          }
                          label="Long Flight"
                        />
                        <FormControlLabel
                          value="2"
                          className="radio-button"
                          control={
                            <Radio
                              sx={{
                                "&.Mui-checked": {
                                  color: Colors.secondary,
                                },
                              }}
                            />
                          }
                          label="Medium Range"
                        />
                        <FormControlLabel
                          value="1"
                          className="radio-button"
                          control={
                            <Radio
                              sx={{
                                "&.Mui-checked": {
                                  color: Colors.secondary,
                                },
                              }}
                            />
                          }
                          label="Short Flight"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Grid className="d-inline-flex">
                        <Grid item xs={12} sm={3}>
                          <InputLabel>Year</InputLabel>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <Select
                            className="w-100 customize-select-opt"
                            label="Select a year"
                            value={selectedYear} 
                            onChange={handleYearChange}
                          >
                            <MenuItem value="All">All</MenuItem>
                            {years.map((year) => (
                              <MenuItem key={year} value={year}>
                                {year}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        {/* <select id="yearSelect" value={selectedYear} onChange={handleYearChange}>
                  <option value="All">All</option>
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select> */}
                      </Grid>
                      <Grid className="d-inline-flex">
                        <Grid item xs={12} sm={3}>
                          <InputLabel>Month</InputLabel>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <Select
                            className="w-100 customize-select-opt"
                            label="Select a month"
                            value={selectedMonth}
                            onChange={handleMonthChange}
                          >
                            {months.map((month) => (
                              <MenuItem key={month.id} value={month.id}>
                                {month.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>

            {pilotMonthlyResult.map((monthlyResult, index) => (
              <div className="result-block" key={index}>
                <h1>{monthlyResult.month}</h1>
                <DataTable
                  header={header}
                  rows={monthlyResult.data}
                  colOrder="desc"
                  loader={loading}
                />
              </div>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default PilotResults;
