import Layout from "../components/Layout/Layout";
import DataTable from "../components/DataTable";
import Advertisement from "../components/Advertisement";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import { InputLabel } from "@mui/material";
import {
  getStateDistrictList,
  getStateandDistrict,
  getDistrictByStateId,
} from "../services/APIService";
import Select from "react-select";

const header = [
  {
    id: "states",
    label: "States",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "districts",
    label: "Districts",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
];

// const data = [
//   { id: 1, states: "All", districts: "All" },
//   { id: 2, states: "Andaman and Nicobar", districts: "Nicobar" },
//   {
//     id: 3,
//     states: "Andaman and Nicobar",
//     districts: "North and Middle Andaman",
//   },
//   { id: 4, states: "Andaman and Nicobar", districts: "South Andaman Nicobar" },
//   { id: 5, states: "Andhra Pradesh", districts: "Alluri Sitharama Raju" },
//   { id: 6, states: "Andhra Pradesh", districts: "Anakapalli" },
//   { id: 7, states: "Andhra Pradesh", districts: "Ananthapuramu" },
// ];
// const data = [];
// const data = getStateDistrictList().then((response) => {
//   return response;
// });

const StateDistricts = () => {
  const [isDisabled, setisDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(data);
  const [state, setState] = useState("All");
  const [district, setDistrict] = useState("All");
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState({ value: '0', label: '' });
  const [selectedState, setSelectedState] = useState({ value: '0', label: 'All' });

  // useEffect(() => {
  //   setFilteredData(
  //     state === "All" || state === null
  //       ? filteredData
  //       : filteredData.filter((dt) => dt.states === state)
  //   );
  // }, [filteredData, state]);

  // useEffect(() => {
  //   setFilteredData(
  //     district === "All" || district === null
  //       ? filteredData
  //       : filteredData.filter((dt) => dt.districts === district)
  //   );
  // }, [filteredData, district]);

  useEffect(() => {
    // console.log('state', state)
    // console.log('district->>>>>>>>>>>>', district)
    // console.log('data', data)
    if ((state === "All" || state === "0") && (district === "All" || district === null || district === "0")) {
      setFilteredData(data);
    } else if (state !== "All" && (district === "All" || district === null || district === "0")) {
      setFilteredData(data.filter((dt) => dt.state_id === state));
    } else if (state === "All" && (district !== "All" || district !== null || district === "0")) {
      setFilteredData(data.filter((dt) => dt.id === district));
    } else {
      setFilteredData(data.filter((dt) => dt.state_id === state && dt.id === district));
    }
  }, [district, state]);

  useEffect(() => {
    setLoading(true);
    getStateDistrictList().then((response) => {
      setLoading(false);
      setData(response.state_districts);
      setFilteredData(response.state_districts);
    });
    getStateandDistrict().then((response) => {
      setLoading(false);
      setDistricts([{ value: "0", label: "All" }, ...response.districts]);
      setStates([{ value: "0", label: "All" }, ...response.states]);
    });
  }, []);

  const stateChange = (selectedOption) => {
    if(selectedOption && selectedOption.value == '0'){
      setisDisabled(true)
      setSelectedDistrict(null)
      setSelectedState(selectedOption)
      setDistrict(null)
      setFilteredData(data);
      setState(selectedOption.value);
    } else {
      if (selectedOption.name && selectedState) {
        if (selectedOption.name !== selectedState.name) {
          setSelectedDistrict(null)
          setSelectedState(selectedOption)
          setDistrict(null)
          setFilteredData(data);
          setState(selectedOption.value);
  
          getDistrictByStateId(selectedOption.value).then((response) => {
            setDistricts([{ value: "0", label: "All" }, ...response.districts]);
          });
        }
      } else {
          setSelectedDistrict(null)
          setSelectedState(selectedOption)
          setDistrict(null)
          setFilteredData(data);
          setState(selectedOption.value);
  
          getDistrictByStateId(selectedOption.value).then((response) => {
            setDistricts([{ value: "0", label: "All" }, ...response.districts]);
          });
        }
        setisDisabled(false)
    }
    
  };

  const districtChange = (selectedOption) => {
    if (selectedOption.name && selectedDistrict) {
      if (selectedOption.name !== selectedDistrict.name) {
        setSelectedDistrict(selectedOption)
        setFilteredData(data);
        setDistrict(selectedOption.value);
      } 
    } else {
      if(selectedOption.value == '0' && selectedDistrict.value == '0'){
        setDistrict(selectedOption.value);
      }else{
        setSelectedDistrict(selectedOption)
        setFilteredData(data);
        setDistrict(selectedOption.value);

      }
    }

  };

  return (
    <Layout>
      <Box sx={{ width: "95%" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={2} display={{ xs: "none", sm: "block" }}>
            <Advertisement slot="5073100067" />
          </Grid>
          <Grid item xs={12} sm={10}>
            <div className="mb-30 gray-100 rounded p-10 lh-base" >
              <p className="mb-10">You will be able to select the State and Districts. Clicking in the name of State or District will give the list of all pilots within that State / District along with their ID, Gender, Nationality, Title and Rating details.</p>
              <p>Clicking on the name of the pilot will navigate you to the Pilot Profile page which will show the profile details of pilot and the link to his/her results in various events.</p>
            </div>
            <Grid
              container
              spacing={2}
              className="justifyContentBetween custom-select-width stateDistFilter"
            >
              <Grid item xs={12} sm={4}>
                <InputLabel>State</InputLabel>
                <Select
                  className="select-filter"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "50px",
                      boxShadow: "0px 0px 15px rgba(228, 236, 255, 0.74)",
                      color: "#65698a80",
                      borderColor: "transparent",
                    }),
                  }}
                  options={states}
                  onChange={stateChange} selectedState
                  value={selectedState}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel>District</InputLabel>
                <Select
                  className="select-filter"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "50px",
                      boxShadow: "0px 0px 15px rgba(228, 236, 255, 0.74)",
                      color: "#65698a80",
                      borderColor: "transparent",
                    }),
                  }}
                  isDisabled={isDisabled}
                  options={districts}
                  onChange={districtChange}
                  value={selectedDistrict}
                />
              </Grid>
            </Grid>
            <DataTable header={header} rows={filteredData} loader={loading} />
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default StateDistricts;
