import React from "react";
import Layout from "../components/Layout/Layout";
import Box from "@mui/material/Box";
import "../styles/PilotStyles.css";
import Search from "../components/Search"
import background from "../images/background.png";

const Pilot = () => {
  return (
    <Layout backGroundImage={background}>
      <Box className="pilot">
        <Search/>
        <h1 className="text-center">Welcome to the All-India Rating System</h1>
        <div>
          <p>
            This website provides pilots with the clear view of their performances in various events. You will be able to search with last name of the pilot, local ID and International ID. Once you click on Search button, the page will show all the results matching with the search criteria.
          </p>
          <p className="mt-20">Clicking on the name of the pilot will navigate you to the Pilot Profile page which will show the profile details of pilot and the link to his/her results in various events.</p>
        </div>
      </Box>
    </Layout>
  );
};

export default Pilot;
