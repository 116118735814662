import React, { useState } from "react";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
} from "@mui/material";
import { Colors } from "../../styles/theme/Colors";
import { Link, NavLink } from "react-router-dom";
import logo from "../../images/Logo.png";
import "../../styles/HeaderStyles.css";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import Search from "../Search";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const managerLoginLink = process.env.REACT_APP_BASE_URL + "/manager/login";

  //handle menu click
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  //menu drawer
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box sx={{ marginBottom: "4em" }}></Box>
      <ul className="mobile-navigation">
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? "active" : "")}
            to={"/"}
          >
            PILOTS
          </NavLink>
        </li>
        <Divider
          sx={{
            borderColor: Colors.divider,
            width: "80%",
            marginLeft: "10%",
            marginRight: "10%",
          }}
        />
        <li>
          <NavLink to={"/state-district"}>STATE/DISTRICT</NavLink>
        </li>
        <Divider
          sx={{
            borderColor: Colors.divider,
            width: "80%",
            marginLeft: "10%",
            marginRight: "10%",
          }}
        />
        <li>
          <NavLink to={"/events"}>EVENTS</NavLink>
        </li>
        <Divider
          sx={{
            borderColor: Colors.divider,
            width: "80%",
            marginLeft: "10%",
            marginRight: "10%",
          }}
        />
        <li>
          <NavLink to={"/top-performers"}>TOP PERFORMERS</NavLink>
        </li>
        <Divider
          sx={{
            borderColor: Colors.divider,
            width: "80%",
            marginLeft: "10%",
            marginRight: "10%",
          }}
        />
        <li>
          <NavLink to={"/help"}>HELP</NavLink>
        </li>
      </ul>
    </Box>
  );

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));

  return (
    <div>
      <Box>
        <AppBar component={"nav"} sx={{ bgcolor: Colors.primary, zIndex: 1 }}>
          <Toolbar sx={{ paddingLeft: { sm: 0 } }}>
            <Box
              sx={{
                backgroundColor: { sm: Colors.logo },
                padding: "3px 15px 3px 15px",
              }}
            >
              <Link href="/">
                <Box
                  component="img"
                  sx={{ height: 54, width: "100%" }}
                  alt="Logo"
                  src={logo}
                />
              </Link>
            </Box>
            <Box
              sx={{
                display: { xs: "none", sm: "none", md: "none", lg: "block" },
                flexGrow: 1,
              }}
            >
              <ul className="navigation-menu">
                <li>
                  <NavLink
                    className={({ isActive }) => (isActive ? "active" : "")}
                    to={"/"}
                  >
                    PILOTS
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/state-district"}>STATE/DISTRICT</NavLink>
                </li>
                <li>
                  <NavLink to={"/events"}>EVENTS</NavLink>
                </li>
                <li>
                  <NavLink to={"/top-performers"}>TOP PERFORMERS</NavLink>
                </li>
                <li>
                  <NavLink to={"/help"}>HELP</NavLink>
                </li>
              </ul>
            </Box>
            <Box
              className="header-search"
              sx={{
                display: { xs: "none", sm: "none", md: "none", lg: "block" },
              }}
            >
              <Search />
            </Box>
            
              <Button
                href={managerLoginLink}
                className = "manager-login"
                color="inherit"
                sx={{
                  backgroundColor: Colors.secondary,
                  borderRadius: "5rem",
                  textTransform: "none",
                  padding: {
                    lg: "5px 20px",
                    md: "5px 25px",
                    sm: "5px 25px",
                    xs: "5px 25px",
                  },
                  fontSize: { lg: "14px", md: "14px", sm: "14px", xs: "14px" },
                  maxWidth: { xl: "150px", lg: "150px" },
                  marginLeft: "20px",
                  position: {
                    lg: "static",
                    md: "absolute",
                    sm: "absolute",
                    xs: "absolute",
                    right: "80px",
                  },
                  flexGrow: 1,
                  fontWeight: 400,
                }}
              >
                Manager Login
              </Button>
            

            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              sx={{
                mr: 2,
                display: { sm: "block", md: "block", lg: "none" },
                backgroundColor: Colors.logo,
                borderRadius: 0,
                position: { xs: "absolute", right: 0, marginRight: 0 },
                padding: { xs: "20px 20px", sm: "16px 20px", md: "16px 20px" },
                "&:hover": {
                  backgroundColor: Colors.logo,
                },
              }}
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            anchor="right"
            sx={{
              display: { xs: "block", sm: "block", md: "block", lg: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                backgroundColor: Colors.logo,
                width: "100%",
              },
            }}
          >
            <DrawerHeader>
              <IconButton
                sx={{ color: Colors.white }}
                onClick={handleDrawerToggle}
              >
                <CloseIcon />
              </IconButton>
            </DrawerHeader>
            {drawer}
          </Drawer>
        </Box>
        <Box>
          <Toolbar />
        </Box>
      </Box>
    </div>
  );
};

export default Header;
