import React from 'react'
// import "../../styles/AccordionStyles.css"
import { Colors } from "../../styles/theme/Colors"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import RemoveIcon from '@mui/icons-material/Remove';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';


const AccordionComp = ({typography, accordionDetails, panelNumber}) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // const Accordion = styled((props) => (
  //   <MuiAccordion disableGutters elevation={0} square {...props} />
  // ))(({ theme }) => ({
  //   border: `1px solid ${theme.palette.divider}`,
  //   '&:not(:last-child)': {
  //     borderBottom: 0,
  //   },
  //   '&:before': {
  //     // display: 'none',
  //   },
  // }));
  
  // const AccordionSummary = styled((props) => (
  //   <MuiAccordionSummary
  //     expandIcon={<ArrowForwardIosSharpIcon />}
  //     {...props}
  //   />
  // ))(({ theme }) => ({
  //   // backgroundColor:
  //   //   theme.palette.mode === 'dark'
  //   //     ? 'rgba(255, 255, 255, .05)'
  //   //     : 'rgba(0, 0, 0, .03)',
  //   // '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
  //     // transform: 'rotate(90deg)',
  //   // },
  //   '& .MuiAccordionSummary-content': {
  //     // marginLeft: theme.spacing(1),
  //   },
  // }));
  
  // const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  //   // padding: theme.spacing(2),
  //   // borderTop: '1px solid rgba(0, 0, 0, .125)',
  // }));

  return (
    <>
      <Accordion disableGutters expanded={expanded === panelNumber} onChange={handleChange(panelNumber)}>
        <AccordionSummary
          expandIcon={expanded === panelNumber?<RemoveIcon sx={{ color: Colors.secondary }} />:<AddIcon sx={{ color: Colors.secondary}} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{typography}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {accordionDetails}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default AccordionComp