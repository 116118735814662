import Layout from "../components/Layout/Layout";
import DataTable from "../components/DataTable";
import Advertisement from "../components/Advertisement";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Select from "react-select";
import { useState, useEffect } from "react";
import { InputLabel } from "@mui/material";
import { getEventsList, getStateandDistrict } from "../services/APIService";

const header = [
  {
    id: "id",
    label: "#",
    width: 130,
    numeric: true,
    disablePadding: false,
  },
  {
    id: "start_date",
    label: "Start Date",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "end_date",
    label: "End Date",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "total_days",
    label: "No. of Days",
    width: 130,
    numeric: true,
    disablePadding: false,
  },
  {
    id: "type",
    label: "Event Type",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "range",
    label: "Range",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "name",
    label: "Event Name",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "manager_name",
    label: "Manager Name",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "event_id",
    label: "Event ID",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "code",
    label: "Event Code",
    width: 130,
    numeric: true,
    disablePadding: false,
  },
  {
    id: "last_update_date",
    label: "Last Updated Date",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
];

const Events = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(data);
  const [state, setState] = useState("All");
  const [district, setDistrict] = useState("All");
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [filteredDistricts, setFilteredDistricts] = useState(districts);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);

  useEffect(() => {
    setLoading(true);
    getEventsList().then((response) => {
      setLoading(false);
      setData(response.events);
      setFilteredData(response.events);
    });
    getStateandDistrict().then((response) => {
      setDistricts([{ value: "0", label: "All" }, ...response.districts]);
      setFilteredDistricts([{ value: "0", label: "All" }, ...response.districts]);
      setStates([{ value: "0", label: "All" }, ...response.states]);
    });
  }, []);

  useEffect(() => {
    // setDistricts(districts.filter((dt) => dt.state_id === state));
    
  }, [state]);

  useEffect(() => {
    // setFilteredData(
    //   district === "0" ? data : data.filter((dt) => dt.district_id === district)
    // );
    // console.log('state', state)
    // console.log('district', district)
    if(state === "0" && (district === "0" || district === null)) {
      setFilteredData(data);
    } else if(state !== "0" && (district === "0" || district === null)) {
      setFilteredData(data.filter((dt) => dt.state_id === state));
    } else if(state === "0" && (district !== "0" || district !== null)) {
      setFilteredData(data.filter((dt) => dt.district_id === district));
    } else {
      setFilteredData(data.filter((dt) => dt.state_id === state && dt.district_id === district));
    }
  }, [district, state]);

  let data_id = 1;

  filteredData.forEach((data) => {
    data.id = data_id++;
  });

  const stateChange = (selectedOption) => {
    setSelectedState(selectedOption);
    setFilteredData(data);
    setFilteredDistricts([{ value: "0", label: "All" }, ...districts.filter((dt) => dt.state_id === selectedOption.value)]);
    setState(selectedOption.value);
    setSelectedDistrict(null)
    setDistrict(null);
  };

  const districtChange = (selectedOption) => {
    setSelectedDistrict(selectedOption)
    setFilteredData(data);
    setDistrict(selectedOption.value);
  };

  return (
    <Layout>
      <Box sx={{ width: "95%" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={2} display={{ xs: "none", sm: "block" }}>
            <Advertisement />
          </Grid>
          <Grid item xs={12} sm={10}>
            <div className="mb-30 gray-100 rounded p-10 lh-base" >
              <p className="mb-10">You will get list of all the events happened in that State / District. Once you click on the Event Name, it will take you to the Event details page which shows all the pilots participated in that event and their results in that event.</p>
              <p>Clicking on the name of the pilot will navigate you to the Pilot Profile page which will show the profile details of pilot and the link to his/her results in various events.</p>
            </div>
            <Grid
              container
              spacing={2}
              className="justifyContentBetween custom-select-width stateDistFilter"
            >
              <Grid item xs={12} sm={4}>
                <InputLabel>State</InputLabel>
                <Select
                  className="select-filter"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "50px",
                      boxShadow: "0px 0px 15px rgba(228, 236, 255, 0.74)",
                      color: "#65698a80",
                      borderColor: "transparent",
                    }),
                  }}
                  options={states}
                  onChange={stateChange}
                  placeholder="Select State"
                  value={selectedState}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel>District</InputLabel>
                <Select
                  className="select-filter"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "50px",
                      boxShadow: "0px 0px 15px rgba(228, 236, 255, 0.74)",
                      color: "#65698a80",
                      borderColor: "transparent",
                    }),
                  }}
                  options={filteredDistricts}
                  onChange={districtChange}
                  placeholder="Select District"
                  value={selectedDistrict}
                />
              </Grid>
            </Grid>
            <DataTable header={header} rows={filteredData} loader={loading} pageName="events" />
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default Events;
