import { useParams } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import { useEffect, useState } from "react";
import { getEventbyEventId } from "../services/APIService";
import { Box, Grid } from "@mui/material";
import Advertisement from "../components/Advertisement";
import DataTable from "../components/DataTable";

const PilotProfile = () => {
  const [loading, setLoading] = useState(false);
  const { event_id } = useParams();
  const [eventData, setEventData] = useState([]);
  const [eventPilots, setEventPilots] = useState([]);
  const [totalRounds, setTotalRounds] = useState(0);
  const [isDispaly, setisDispaly] = useState(false);

  const header = [
    {
      id: "id",
      label: "#",
      width: 130,
      numeric: true,
      disablePadding: false,
    },
    {
      id: "gender",
      label: "Gender",
      width: 130,
      numeric: false,
      disablePadding: false,
    },
    {
      id: "nationality",
      label: "Nationality",
      width: 130,
      numeric: false,
      disablePadding: false,
    },
    {
      id: "name",
      label: "First Name",
      width: 130,
      numeric: false,
      disablePadding: false,
    },
    {
      id: "lname",
      label: "Last Name",
      width: 130,
      numeric: false,
      disablePadding: false,
    },
    {
      id: "emp_id",
      label: "Emp ID",
      width: 130,
      numeric: false,
      disablePadding: false,
    },
    {
      id: "rating",
      label: "Rating",
      width: 130,
      numeric: false,
      disablePadding: false,
    },
  ];

  useEffect(() => {
    setisDispaly(false)
    setLoading(true);
    getEventbyEventId(event_id).then((event) => {
      setLoading(false);
      setEventData(event.eventData);
      setTotalRounds(event?.eventData?.total_rounds);
      if (event.eventData.event_pilots) {
        setisDispaly(false)
        setEventPilots(event.eventData.event_pilots);
      } else {
        setisDispaly(true)
        setEventPilots([]);
      }
    });
  }, [event_id]);

  if (totalRounds > 0) {
    for (var i = 1; i <= totalRounds; i++) {
      var round = {
        id: "r" + i,
        label: "R" + i,
        width: 130,
        numeric: true,
        disablePadding: false,
      };
      header.push(round);
    }
    var rounds_total = {
      id: "rounds_total",
      label: "Total",
      width: 130,
      numeric: true,
      disablePadding: false,
    };
    header.push(rounds_total);
  }

  let data_id = 1;

  eventPilots.forEach((data) => {
    data.id = data_id++;
  });

  return (
    <Layout>
      <Box sx={{ width: "95%" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={2} display={{ xs: "none", sm: "block" }}>
            <Advertisement />
          </Grid>
          <Grid item xs={12} sm={12} md={10}>

            {loading ? (
              <Box sx={{}}>
                <div className="stage" >
                  <div className="dot-windmill"></div>
                </div>
              </Box>) :
              <Grid
                container
                spacing={2}
                className="justifyContentBetween custom-select-width custom-pl"
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{}}>
                    <p className="event-name-date">
                      Event: <strong>{eventData?.name}</strong>
                    </p>
                  </Box>
                  <Box sx={{}}>
                    <p className="event-name-date">
                      {" "}
                      Date: <strong>{eventData?.start_date}</strong>
                    </p>
                  </Box>
                </Box>
              </Grid> }
            {isDispaly && eventPilots.length === 0 && (
              <div className="no-results">No Results</div>
            )}
            {eventPilots.length > 0 && (
              <DataTable header={header} rows={eventPilots} />
            )}
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default PilotProfile;
