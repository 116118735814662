import Layout from "../components/Layout/Layout";
import DataTable from "../components/DataTable";
import Advertisement from "../components/Advertisement";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Select from "react-select";
import { useState, useEffect } from "react";
import { InputLabel } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Colors from "../styles/theme/Colors";
import FormHelperText from "@mui/material/FormHelperText";
import { getStateandDistrict, getTopPerformers,getDistrictByStateId } from "../services/APIService";

const header = [
  {
    id: "id",
    label: "#",
    width: 130,
    numeric: true,
    disablePadding: false,
  },
  {
    id: "membership_id",
    label: "Membership",
    width: 130,
    numeric: true,
    disablePadding: false,
  },
  {
    id: "gender",
    label: "Gender",
    width: 130,
    numeric: true,
    disablePadding: false,
  },
  {
    id: "nationality_name",
    label: "Nationality",
    width: 130,
    numeric: true,
    disablePadding: false,
  },
  {
    id: "title",
    label: "Title",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "name",
    label: "First Name",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "lname",
    label: "Last Name",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "published_rating",
    label: "Rating",
    width: 130,
    numeric: true,
    disablePadding: false,
  },
  {
    id: "state_name",
    label: "State",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "district_name",
    label: "District",
    width: 130,
    numeric: true,
    disablePadding: false,
  },
  {
    id: "emp_id",
    label: "EMP ID",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "int_id",
    label: "INT ID",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
];

const genders = [
  { value: "all", label: "All" },
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
];

const ages = [
  { value: 0, label: "All" },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
  { value: 11, label: 11 },
  { value: 12, label: 12 },
  { value: 13, label: 13 },
  { value: 14, label: 14 },
  { value: 15, label: 15 },
  { value: 16, label: 16 },
  { value: 17, label: 17 },
  { value: 18, label: "Above 18" },
];

const TopPerformers = () => {
  const [isDisabled, setisDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(data);
  const [state, setState] = useState(0);
  const [district, setDistrict] = useState(0);
  const [eventType, setEventType] = useState("1");
  const [range, setRange] = useState("3");
  const [gender, setGender] = useState("all");
  const [age, setAge] = useState(0);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [isDispaly, setisDispaly] = useState(false);
  const [selectedDistrict, setSelectedDistrict] = useState({ value: '0', label: '' });
  const [selectedState, setSelectedState] = useState({ value: '0', label: 'All' });

  useEffect(() => {
    setisDispaly(false)
    setLoading(true);
    getTopPerformers(
      age,
      gender,
      state,
      district,
      eventType,
      range,).then((response) => {
        setLoading(false);
      if(response && response.top_pilots.length > 0){
        setisDispaly(false)
        setData(response.top_pilots);
      } else {
        setisDispaly(true)
        setData([]);
      }
    });
    getStateandDistrict().then((response) => {
      // setDistricts([{ value: 0, label: "All" }, ...response.districts]);
      setStates([{ value: 0, label: "All" }, ...response.states]);
    });
  }, [
    age,
    gender,
    state,
    district,
    eventType,
    range,
  ]);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  // useEffect(() => {
  //   setFilteredData(
  //     data?.filter(
  //       (dt) =>
  //         (eventType === "0" || dt.type.includes(parseInt(eventType, 10))) &&
  //         (range === "0" || dt.range.includes(parseInt(range, 10))) &&
  //         (gender === "all" || dt.gender === gender) &&
  //         (age === 0 ||
  //           (age === 18 && dt.age >= age) ||
  //           (age !== 18 && dt.age <= age)) &&
  //         (state === 0 || dt.state_id === state) &&
  //         (district === 0 || dt.district_id === district)
  //     )
  //   );
  // }, [data, range, eventType, gender, age, state, district]);

  const ageChange = (selectedOption) => {
    setAge(selectedOption.value);
  };

  const genderChange = (selectedOption) => {
    setGender(selectedOption.value);
  };

  let data_id = 1;

  filteredData.forEach((data) => {
    data.id = data_id++;
  });

  const stateChange = (selectedOption) => {
    // setState(selectedOption.value);
      if (selectedOption.name && selectedState) {
        if (selectedOption.name !== selectedState.name) {
          setSelectedDistrict(null)
          setSelectedState(selectedOption)
          setDistrict(null)
          // setFilteredData(data);
          setState(selectedOption.value);
  
          getDistrictByStateId(selectedOption.value).then((response) => {
            setDistricts(response.districts);
          });
        }
      } else {
        setSelectedDistrict(null)
        setSelectedState(selectedOption)
        setDistrict(null)
        setFilteredData(data);
        setState(selectedOption.value);

        getDistrictByStateId(selectedOption.value).then((response) => {
          setDistricts([{ value: "0", label: "All" }, ...response.districts]);
        });
    }
      setisDisabled(false)
  };

  const districtChange = (selectedOption) => {
    // setDistrict(selectedOption.value);
    if (selectedOption.name && selectedDistrict) {
      if (selectedOption.name !== selectedDistrict.name) {
        setSelectedDistrict(selectedOption)
        setFilteredData(data);
        setDistrict(selectedOption.value);
      }
    } else {
      setSelectedDistrict(selectedOption)
      setFilteredData(data);
      setDistrict(selectedOption.value);
    }
    
  };

  return (
    <Layout>
      <Box sx={{ width: "95%" }}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={2}
            display={{ xs: "none", sm: "none", md: "none", lg: "block" }}
          >
            <Advertisement />
          </Grid>
          <Grid item xs={12} sm={12} md={10}>
            <div className="mb-30 gray-100 rounded p-10 lh-base" >
              <p>You will be able to apply various filters and search pilots based on those filters.
                Various filters available are Flight Type, Gender, Age, State and District. Please visit Section 4 in Help section to understand more on how the ratings are calculated.
              </p>
            </div>
            <Grid
              container
              spacing={2}
              className="justifyContentBetween custom-select-width custom-pl"
            >
              <Grid item xs={6} sm={6} lg={2}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={eventType}
                  onChange={(e) => setEventType(e.target.value)}
                >
                  {/* <FormControlLabel
                    value="0"
                    className="radio-button"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: Colors.secondary,
                          },
                        }}
                      />
                    }
                    label="All"
                  /> */}
                  <FormControlLabel
                    value="1"
                    className="radio-button"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: Colors.secondary,
                          },
                        }}
                      />
                    }
                    label="Flight"
                  />
                  <FormControlLabel
                    value="2"
                    className="radio-button"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: Colors.secondary,
                          },
                        }}
                      />
                    }
                    label="Simulation"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={6} sm={6} lg={2}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={range}
                  onChange={(e) => setRange(e.target.value)}
                >
                  {/* <FormControlLabel
                    value="0"
                    className="radio-button"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: Colors.secondary,
                          },
                        }}
                      />
                    }
                    label="All"
                  /> */}
                  <FormControlLabel
                    value="3"
                    className="radio-button"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: Colors.secondary,
                          },
                        }}
                      />
                    }
                    label="Long Flight"
                  />
                  <FormControlLabel
                    value="2"
                    className="radio-button"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: Colors.secondary,
                          },
                        }}
                      />
                    }
                    label="Medium Range"
                  />
                  <FormControlLabel
                    value="1"
                    className="radio-button"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: Colors.secondary,
                          },
                        }}
                      />
                    }
                    label="Short Flight"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Grid container className="mb-15">
                  <Grid item xs={12} sm={3} lg={3}>
                    <InputLabel className="mt-10 form-label-ui">
                      Gender
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={9} lg={9}>
                    <Select
                      className="select-filter"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderRadius: "50px",
                          boxShadow: "0px 0px 15px rgba(228, 236, 255, 0.74)",
                          color: "#65698a80",
                          borderColor: "transparent",
                        }),
                      }}
                      options={genders}
                      onChange={genderChange}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={3} lg={3}>
                    <InputLabel className="mt-10 mb-5 form-label-ui">
                      Age Less Than
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={9} lg={9}>
                    <Select
                      className="select-filter"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderRadius: "50px",
                          boxShadow: "0px 0px 15px rgba(228, 236, 255, 0.74)",
                          color: "#65698a80",
                          borderColor: "transparent",
                        }),
                      }}
                      options={ages}
                      onChange={ageChange}
                    />
                    <FormHelperText className="form-note">
                      * Age is taken from 1-Jan of that year.
                    </FormHelperText>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Grid container className="mb-15">
                  <Grid item xs={12} sm={3} lg={3}>
                    <InputLabel className="mt-10 form-label-ui">
                      State
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={9} lg={9}>
                    <Select
                      className="select-filter"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderRadius: "50px",
                          boxShadow: "0px 0px 15px rgba(228, 236, 255, 0.74)",
                          color: "#65698a80",
                          borderColor: "transparent",
                        }),
                      }}
                      options={states}
                      onChange={stateChange} selectedState
                      value={selectedState}
                    />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12} sm={3} lg={3}>
                    <InputLabel className="mt-10 mb-5 form-label-ui">
                      District
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={9} lg={9}>
                    <Select
                      className="select-filter"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderRadius: "50px",
                          boxShadow: "0px 0px 15px rgba(228, 236, 255, 0.74)",
                          color: "#65698a80",
                          borderColor: "transparent",
                        }),
                      }}
                      isDisabled={isDisabled}
                      options={districts}
                      onChange={districtChange}
                      value={selectedDistrict}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <DataTable header={header} rows={filteredData} displayMessage={isDispaly} loader={loading}/>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default TopPerformers;
