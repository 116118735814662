import { BrowserRouter, Routes, Route } from "react-router-dom";
import Pilot from "./pages/Pilot";
import StateDistricts from "./pages/StateDistricts";
import Events from "./pages/Events";
import TopPerformers from "./pages/TopPerformers";
import Help from "./pages/Help";
import PilotProfile from "./pages/PilotProfile";
import PilotResults from "./pages/PilotResults";
import EventDetails from "./pages/EventDetails";
import Pilots from "./pages/Pilots";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PageNotFound from "./pages/PageNotFound";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Pilot />} />
          <Route path="/state-district" element={<StateDistricts />} />
          <Route path="/events" element={<Events />} />
          <Route path="/top-performers" element={<TopPerformers />} />
          <Route path="/help" element={<Help />} />
          <Route path="/profile/:emp_id" element={<PilotProfile />} />
          <Route path="/results/:emp_id" element={<PilotResults />} />
          <Route path="/event/:event_id" element={<EventDetails />} />
          <Route path="/pilots" element={<Pilots />} />
          <Route path="/pilots/:state_id" element={<Pilots />} />
          <Route path="/pilots/:state_id/:district_id" element={<Pilots />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
