import { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import {
  getDistrictByStateId,
  getPilotsByStateDistrict,
  getStateandDistrict,
} from "../services/APIService";
import { Box, Grid, InputLabel } from "@mui/material";
import Advertisement from "../components/Advertisement";
import Select from "react-select";
import { DataGrid } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../components/CustomNoRowsOverlay";
import { useParams } from "react-router-dom";
import data from "../components/common/data.json";
import { Link } from "react-router-dom";

const Pilots = () => {
  const { state_id, district_id } = useParams();
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [state, setState] = useState(state_id);
  const [selectedState, setSelectedState] = useState(null);
  const [district, setDistrict] = useState(district_id);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [pilots, setPilots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPilots, setTotalPilots] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [filteredDistricts, setFilteredDistricts] = useState(districts);

  const columns = [
    {
      field: "field_id",
      headerName: "#",
      headerAlign: "center",
      align: "center",
      // flex: 1,
      headerClassName:
        "grid-header border-right fw-700 font-size-14 label-color text-center",
      width: 50,
    },
    {
      field: "emp_id",
      headerName: "Emp ID",
      headerAlign: "center",
      align: "center",
      // flex: 2,
      headerClassName:
        "grid-header border-right fw-700 font-size-14 label-color text-center",
      renderCell: (params) => {
        return (
          <Link
            to={{ pathname: "/profile/" + params.formattedValue }}
            key={params.formattedValue}
            className="link"
          >
            {params.formattedValue}
          </Link>
        );
      },
      width: 110,
    },
    {
      field: "membership_id",
      headerName: "Membership",
      headerAlign: "center",
      align: "center",
      // flex: 2.5,
      headerClassName:
        "grid-header border-right fw-700 font-size-14 label-color text-center",
      renderCell: (params) => {
        const cellClass =
          data.membership[params.row.membership_id].toLowerCase() + "-membr";

        return (
          <div className={cellClass}>
            {data.membership[params.row.membership_id]}
          </div>
        );
      },
      width: 100,
    },
    {
      field: "gender",
      headerName: "Gender",
      headerAlign: "center",
      // align: "center",
      // flex: 2,
      headerClassName:
        "grid-header border-right fw-700 font-size-14 label-color text-center",
      width: 100,
    },
    {
      field: "nationality_name",
      headerName: "Nationality",
      headerAlign: "center",
      // align: "center",
      // flex: 2.5,
      headerClassName:
        "grid-header border-right fw-700 font-size-14 label-color text-center",
      width: 100,
    },
    {
      field: "title",
      headerName: "Title",
      headerAlign: "center",
      align: "center",
      flex: 2,
      headerClassName:
        "grid-header border-right fw-700 font-size-14 label-color text-center",
      width: 100,
    },
    {
      field: "name",
      headerName: "First Name",
      headerAlign: "center",
      align: "center",
      // flex: 2,
      headerClassName:
        "grid-header border-right fw-700 font-size-14 label-color text-center",
      width: 100,
    },
    // {
    //   field: "mname",
    //   headerName: "Middle Name",
    //   headerAlign: "center",
    //   align: "center",
    //   headerClassName:
    //     "grid-header border-right fw-700 font-size-14 label-color text-center",
    //   width: 100,
    // },
    {
      field: "lname",
      headerName: "Last Name",
      headerAlign: "center",
      align: "center",
      // flex: 2,
      headerClassName:
        "grid-header border-right fw-700 font-size-14 label-color text-center",
      width: 100,
    },
    {
      field: "state_name",
      headerName: "State ",
      headerAlign: "center",
      align: "center",
      // flex: 2,
      headerClassName:
        "grid-header border-right fw-700 font-size-14 label-color text-center",
      width: 100,
    },
    {
      field: "district_name",
      headerName: "District ",
      headerAlign: "center",
      align: "center",
      // flex: 2,
      headerClassName:
        "grid-header border-right fw-700 font-size-14 label-color text-center",
      width: 200,
    },
    {
      field: "f_long_flight",
      headerName: "Long Flight",
      headerAlign: "center",
      align: "center",
      // flex: 2,
      headerClassName:
        "grid-header fw-700 font-size-14 label-color text-center",
      width: 50,
    },
    {
      field: "f_medium_range",
      headerName: "Medium Range",
      headerAlign: "center",
      align: "center",
      // flex: 2,
      headerClassName:
        "grid-header fw-700 font-size-14 label-color text-center",
      width: 101,
    },
    {
      field: "f_short_flight",
      headerName: "Short Flights",
      headerAlign: "center",
      align: "center",
      // flex: 2,
      headerClassName:
        "grid-header border-right fw-700 font-size-14 label-color text-center",
      width: 50,
    },
    {
      field: "s_long_flight",
      headerName: "Long Flight",
      headerAlign: "center",
      align: "center",
      flex: 2,
      headerClassName:
        "grid-header fw-700 font-size-14 label-color text-center",
      width: 100,
    },
    {
      field: "s_medium_range",
      headerName: "Medium Range",
      headerAlign: "center",
      align: "center",
      // flex: 2,
      headerClassName:
        "grid-header fw-700 font-size-14 label-color text-center",
      width: 101,
    },
    {
      field: "s_short_flight",
      headerName: "Short Flights",
      headerAlign: "center",
      align: "center",
      flex: 2,
      headerClassName:
        "grid-header fw-700 font-size-14 label-color text-center",
      width: 200,
    },
  ];

  const columnGroupingModel = [
    {
      groupId: "Flight Rating",
      children: [
        { field: "f_long_flight" },
        { field: "f_medium_range" },
        { field: "f_short_flight" },
      ],
      headerAlign: "center",
      headerClassName:
        "grid-sub-header fw-700 font-size-15 label-color text-center border-right border-left",
    },
    {
      groupId: "Simulation Rating",
      children: [
        { field: "s_long_flight" },
        { field: "s_medium_range" },
        { field: "s_short_flight" },
      ],
      headerAlign: "center",
      headerClassName:
        "grid-sub-header fw-700 font-size-15 label-color text-center",
    },
  ];

  useEffect(() => {
    getStateandDistrict().then((response) => {
      setDistricts([{ value: "0", label: "All" }, ...response.districts]);
      setFilteredDistricts([
        { value: "0", label: "All" },
        ...response.districts,
      ]);
      setStates([{ value: "0", label: "All" }, ...response.states]);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    getPilotsByStateDistrict(
      state,
      district,
      paginationModel.page + 1,
      paginationModel.pageSize
    ).then((response) => {
      setLoading(false);
      response.pilots ? setPilots(response.pilots) : setPilots([]);
      response.pagination?.total_pilots
        ? setTotalPilots(response.pagination?.total_pilots)
        : setTotalPilots(0);
      if (response.pilots) {
        const pilotsWithAutoId = response.pilots.map((pilot, index) => {
          var autoId = index + 1;
          if (paginationModel.page > 0) {
            autoId = paginationModel.page * paginationModel.pageSize + autoId;
          }
          return {
            ...pilot,
            field_id: autoId,
          };
        });
        setPilots(pilotsWithAutoId);
      }
      // setLoading(false);
    });
  }, [state, district, paginationModel]);

  useEffect(() => {
    if (states.length > 0 && state) {
      const initialState = states.find((obj) => {
        return obj.value === parseInt(state);
      });
      setSelectedState(initialState);

      setFilteredDistricts([
        { value: "0", label: "All" },
        ...districts.filter((dt) => dt.state_id === parseInt(state)),
      ]);
    }

    if (districts.length > 0 && district) {
      const initialDistrict = districts.find((obj) => {
        return obj.value === parseInt(district);
      });
      setSelectedDistrict(initialDistrict);
    }
  }, [states, state, districts, district]);

  const onPageSizeChange = (newPageSize) => {
    setPaginationModel({
      page: 0,
      pageSize: newPageSize,
    });
  };

  const stateChange = (selectedOption) => {
    setSelectedState(selectedOption);
    setFilteredDistricts([
      { value: "0", label: "All" },
      ...districts.filter((dt) => dt.state_id === selectedOption.value),
    ]);
    setPaginationModel({
      page: 0,
      pageSize: paginationModel.pageSize,
    });
    setState(selectedOption.value);
    setSelectedDistrict(null);
    setDistrict(null);
  };

  const districtChange = (selectedOption) => {
    setPaginationModel({
      page: 0,
      pageSize: paginationModel.pageSize,
    });
    setSelectedDistrict(selectedOption);
    setDistrict(selectedOption.value);
  };

  return (
    <Layout>
      <Box sx={{ width: "95%" }}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={2}
            display={{ xs: "none", sm: "none", md: "none", lg: "block" }}
          >
            <Advertisement />
          </Grid>
          <Grid item xs={12} sm={12} md={10}>
            <Grid
              container
              spacing={2}
              className="justifyContentBetween custom-select-width custom-pl"
            >
              <Grid item xs={12} sm={4}>
                <InputLabel>State</InputLabel>
                <Select
                  className="select-filter"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "50px",
                      boxShadow: "0px 0px 15px rgba(228, 236, 255, 0.74)",
                      color: "#65698a70",
                      borderColor: "transparent",
                    }),
                  }}
                  options={states}
                  value={selectedState}
                  onChange={stateChange}
                  placeholder="Select State"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel>District</InputLabel>
                <Select
                  className="select-filter"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "50px",
                      boxShadow: "0px 0px 15px rgba(228, 236, 255, 0.74)",
                      color: "#65698a70",
                      borderColor: "transparent",
                    }),
                  }}
                  options={filteredDistricts}
                  placeholder="Select District"
                  value={selectedDistrict}
                  onChange={districtChange}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <div
                className="pilot-MUIGrid"
                style={{
                  width: "100%",
                  height: pilots && pilots.length === 0 ? "" : "680px",
                }}
              >
                {loading ? (
                  <Grid>
                    <div className="stage" >
                      <div className="dot-windmill"></div>
                    </div>
                  </Grid>) :
                  <DataGrid
                    className="dataGridProfile"
                    experimentalFeatures={{ columnGrouping: true }}
                    rows={pilots}
                    columns={columns}
                    components={{
                      noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    columnReorder
                    disableRowSelectionOnClick
                    columnGroupingModel={columnGroupingModel}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          id: false,
                        },
                      },
                    }}
                    rowHeight={52}
                    columnHeaderHeight={50}
                    sx={{
                      "& > .MuiDataGrid-columnSeparator": {
                        visibility: "hidden",
                      },
                    }}
                    pagination
                    paginationModel={paginationModel}
                    rowCount={totalPilots}
                    paginationMode="server"
                    onPaginationModelChange={setPaginationModel}
                    loader={loading}
                    pageSizeOptions={[10, 50, 100]}
                    onPageSizeChange={(newPageSize) =>
                      onPageSizeChange(newPageSize)
                    }
                  /> }
              </div>
            </Grid> 
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default Pilots;
