import React, { useEffect  } from 'react';

const AdsComponent = (props) => {
    const { dataAdSlot } = props;  
    useEffect(() => {

        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }

        catch (e) {

        }

    },[]);


    return (
        <div className='adparent' align ='center' style ={{ width : 273,margin:'auto',textAlign:'center'}}>
            <ins className="adsbygoogle responsive_ads"
                style={{ display: "block" }}
                data-ad-client="ca-pub-6112588494188631"
                data-ad-slot={dataAdSlot}
                data-ad-format="auto"
                data-adtest="on"
                data-full-width-responsive="true">
            </ins>
        </div>
    );
};

export default AdsComponent;